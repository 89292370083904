// Lib
import { FC, memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
// Api
import {
  useCreateMenuScheduleMutation,
  useUpdateMenuScheduleMutation,
} from "rtkQuery/query/menuSchedulingAPI";
// Hooks
import { useNotification, useViewport } from "hooks";
import { useAppSelector } from "hooks/redux";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { ScheduleDay } from "types/menuScheduling";
import { PublishOutOfStock, PublishResponseStatus } from "types/menus";
import { ScheduleFormTypes, ScheduleModalProps } from "./types";
// Theme
import { theme } from "theme";
// Constants
import { NOTIFICATIONS } from "consts";
// Helpers
import {
  createScheduleFormData,
  createScheduleFormRequesData,
} from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PlusIcon, ProductIcon, TrashIcon } from "icons";
// Components
import { ConfirmDialog, Modal } from "components";
import { DatePicker, Select, TagsSelect } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import { Form, FormInputsContainer } from "./styled";
import { ErrorMessage } from "components/Form/styled";

import { selectDayButtonValues } from "./config";
import { resolver } from "./validation";

import { defaultScheduleValue, defaultValues } from "./defaultValues";

const DEFAULT_UNAVAILABLE_ITEMS_VALUES = {
  menuid: "",
  locationId: "",
  show: false,
  count: 0,
};

export const ScheduleModal: FC<ScheduleModalProps> = memo(
  ({ scheduleModal, locationsData, menusData, isLoading, onClose }) => {
    const { isTabletPortrait, isMobile } = useViewport();

    const companyId = useAppSelector(getUserCompanyId);

    const navigate = useNavigate();

    const { openNotification } = useNotification();

    const isEdit = !!scheduleModal && typeof scheduleModal !== "boolean";

    const [create, { isLoading: isCreateLoading }] =
      useCreateMenuScheduleMutation();
    const [update, { isLoading: isUpdateLoading }] =
      useUpdateMenuScheduleMutation();

    const [unavailableItemsModal, setUnavailableItemsModal] = useState<
      typeof DEFAULT_UNAVAILABLE_ITEMS_VALUES
    >(DEFAULT_UNAVAILABLE_ITEMS_VALUES);

    const {
      handleSubmit,
      control,
      setValue,
      getValues,
      watch,
      formState: { errors },
      reset,
    } = useForm<ScheduleFormTypes>({
      defaultValues,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolver,
    });

    useEffect(() => {
      if (scheduleModal === false) {
        reset(defaultValues);
      }

      if (scheduleModal && typeof scheduleModal !== "boolean") {
        reset(createScheduleFormData(scheduleModal));
        setUnavailableItemsModal(DEFAULT_UNAVAILABLE_ITEMS_VALUES);
      }

      setUnavailableItemsModal(DEFAULT_UNAVAILABLE_ITEMS_VALUES);
    }, [scheduleModal]);

    const publishTimes = watch("publishTimes");
    const selectedMenu = watch("menuIds");

    const { fields, remove } = useFieldArray({
      control,
      name: "publishTimes",
    });

    const menusOptions = menusData?.data?.map(menu => ({
      label: menu.name,
      value: menu.id,
      locationId: menu.siteId,
    }));

    const locationsList = locationsData?.map(l => ({
      label: l.name,
      value: l.id,
      renderValue: l.name,
    }));

    const locationOptions = selectedMenu
      ? locationsList.filter(
          l =>
            l.value ===
            menusOptions.find(m => m.value === selectedMenu)?.locationId,
        )
      : [];

    const channelsOptions = locationsData
      ?.filter(location =>
        locationOptions?.find(el => el.value === location.id),
      )
      ?.flatMap(location =>
        location?.channels?.map(channel => ({
          label: channel.name,
          value: channel.id,
          renderValue: channel.name,
        })),
      );

    const handleSetDay = (value: ScheduleDay, index: number) => {
      const values = getValues(`publishTimes.${index}.days`);

      const newValue = values?.includes(value)
        ? values.filter(v => v !== value)
        : values.concat(value);
      setValue(`publishTimes.${index}.days`, newValue, {
        shouldValidate: true,
      });
    };

    const handleAdd = (index: number) => {
      const values = getValues(`publishTimes`);

      const newValues = [
        ...values.slice(0, index + 1),
        defaultScheduleValue,
        ...values.slice(index + 1),
      ];

      setValue(`publishTimes`, newValues);
    };

    const handleClose = () => {
      if (isLoading || isCreateLoading || isUpdateLoading) {
        return;
      }

      onClose();
    };

    const onMenuChange = () => {
      setValue("sitesIds", []);
      setValue("channelIds", []);
    };

    const onSubmit: SubmitHandler<ScheduleFormTypes> = async formData => {
      try {
        if (!isEdit) {
          const data = createScheduleFormRequesData(formData);

          const response = await create({
            companyId,
            outOfStock: PublishOutOfStock.NOT_IGNORE,
            ...data,
          }).unwrap();

          if (response?.status === PublishResponseStatus.WARNING) {
            setUnavailableItemsModal({
              show: true,
              count: response.outOfStockItems?.length,
              menuid: formData.menuIds,
              locationId: formData.sitesIds[0],
            });

            return;
          }

          openNotification({ message: NOTIFICATIONS.SCHEDULE_CREATED });
        } else {
          const id = scheduleModal.id;
          const data = createScheduleFormRequesData(formData);

          await update({ id, ...data }).unwrap();

          openNotification({ message: NOTIFICATIONS.SCHEDULE_UPDATED });
        }

        onClose();
      } catch (error) {
        errorHandler({ error, openNotification });
      }
    };

    const onPublishConfirmSubmit: SubmitHandler<
      ScheduleFormTypes
    > = async formData => {
      try {
        const data = createScheduleFormRequesData(formData);

        const response = await create({
          companyId,
          outOfStock: PublishOutOfStock.IGNORE,
          ...data,
        }).unwrap();

        if (response?.status === PublishResponseStatus.WARNING) {
          setUnavailableItemsModal({
            show: true,
            count: response.outOfStockItems?.length,
            menuid: formData.menuIds,
            locationId: formData.sitesIds[0],
          });

          return;
        }

        openNotification({ message: NOTIFICATIONS.SCHEDULE_CREATED });

        onClose();
      } catch (error) {
        errorHandler({ error, openNotification });
      }
    };

    const handleClosePublishConfirmModal = () => {
      setUnavailableItemsModal(prev => ({ ...prev, show: false }));
    };

    const onInventoryClick = () => {
      navigate("/loop/inventory?tab=out_of_stock", {
        state: {
          locationIdValue: unavailableItemsModal.locationId,
          menuIdValue: unavailableItemsModal.menuid,
        },
      });
    };

    const onPublishConfirm = async () => {
      handleSubmit(onPublishConfirmSubmit)();
    };

    return (
      <>
        <Modal
          title={"Add schedule"}
          open={!!scheduleModal}
          width={728}
          onClose={handleClose}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormInputsContainer>
              <Controller
                name="timezone"
                control={control}
                render={({ field, fieldState }) => (
                  <Select
                    required
                    label="Timezone"
                    {...field}
                    fieldState={fieldState}
                    disabled={isCreateLoading || isUpdateLoading}
                    options={[{ label: "Asia/Kuwait", value: "Asia/Kuwait" }]}
                  />
                )}
              />

              <Controller
                name="menuIds"
                control={control}
                render={({ field, fieldState }) => (
                  <Select
                    required
                    label="Menu"
                    {...field}
                    fieldState={fieldState}
                    disabled={isCreateLoading || isUpdateLoading}
                    options={menusOptions || []}
                    onSelect={onMenuChange}
                  />
                )}
              />

              <Controller
                name="sitesIds"
                control={control}
                render={({ field, fieldState }) => (
                  <TagsSelect
                    required
                    selectAll
                    label="Locations"
                    {...field}
                    fieldState={fieldState}
                    disabled={isCreateLoading || isUpdateLoading}
                    options={locationOptions || []}
                  />
                )}
              />

              <Controller
                name="channelIds"
                control={control}
                render={({ field, fieldState }) => (
                  <TagsSelect
                    required
                    selectAll
                    label="Channel links"
                    {...field}
                    fieldState={fieldState}
                    disabled={isCreateLoading || isUpdateLoading}
                    options={channelsOptions || []}
                  />
                )}
              />

              {fields?.map((scheduleField, index) => (
                <div key={scheduleField.id}>
                  <FlexContainer $gap={4}>
                    <FlexContainer $gap={4} $column={isTabletPortrait}>
                      <div>
                        <Typography.Label>Select days*</Typography.Label>

                        <FlexContainer $gap={4} $wrap={isTabletPortrait}>
                          {selectDayButtonValues.map(button => (
                            <Button.Form
                              disabled={isCreateLoading || isUpdateLoading}
                              key={button.value}
                              $width={"60px"}
                              type={
                                publishTimes[index].days?.includes(button.value)
                                  ? "primary"
                                  : "default"
                              }
                              onClick={() => handleSetDay(button.value, index)}
                            >
                              {button.label}
                            </Button.Form>
                          ))}
                        </FlexContainer>

                        {errors?.publishTimes?.length && (
                          <ErrorMessage>
                            {errors?.publishTimes[index]?.days?.message}
                          </ErrorMessage>
                        )}
                      </div>

                      <div style={{ width: "100%" }}>
                        <Controller
                          name={`publishTimes.${index}.time`}
                          control={control}
                          render={({ field, fieldState }) => (
                            <DatePicker
                              label="Publish time*"
                              picker="time"
                              format={"HH:mm:ss"}
                              {...field}
                              fieldState={fieldState}
                              disabled={isCreateLoading || isUpdateLoading}
                              // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                              onChange={field.onChange as any}
                            />
                          )}
                        />
                      </div>
                    </FlexContainer>

                    <FlexContainer
                      $gap={isTabletPortrait ? 8 : 4}
                      $margin={isTabletPortrait ? "24px 0 0 4px" : "24px 0 0"}
                      $justify={isTabletPortrait ? "center" : "flex-start"}
                      $column={isTabletPortrait}
                    >
                      <Button.SquaredIcon
                        icon={<TrashIcon fill={theme.color.text.tertiary} />}
                        disabled={
                          publishTimes?.length < 2 ||
                          isCreateLoading ||
                          isUpdateLoading
                        }
                        onClick={() => remove(index)}
                      />

                      <Button.SquaredIcon
                        icon={<PlusIcon fill={theme.color.text.tertiary} />}
                        disabled={isCreateLoading || isUpdateLoading}
                        onClick={() => handleAdd(index)}
                      />
                    </FlexContainer>
                  </FlexContainer>
                </div>
              ))}
            </FormInputsContainer>

            <FlexContainer
              $fullwidth
              $align="center"
              $justify="flex-end"
              $gap={8}
              $padding="24px 0 0"
              $column={isMobile}
            >
              <Button.Heading
                $fullWidth={isMobile}
                onClick={handleClose}
                disabled={isCreateLoading || isUpdateLoading}
              >
                Cancel
              </Button.Heading>

              <Button.Heading
                $fullWidth={isMobile}
                type="primary"
                htmlType="submit"
                loading={isCreateLoading || isUpdateLoading}
              >
                Save
              </Button.Heading>
            </FlexContainer>
          </Form>
        </Modal>

        <ConfirmDialog
          open={unavailableItemsModal.show}
          Icon={ProductIcon}
          message={`Please pay attention that the menu you publish contains ${unavailableItemsModal.count} items with zero quantity.`}
          description={
            "Confirm publishing so these items will have snoozed status or change the quantity in the Inventory"
          }
          firstCTAButton={{
            title: "Inventory",
            type: "primary",
            onClick: () => onInventoryClick(),
          }}
          secondCTAButton={{
            title: "Publish",
            status: "danger",
            onClick: () => onPublishConfirm(),
          }}
          onCancel={handleClosePublishConfirmModal}
        />
      </>
    );
  },
);

ScheduleModal.displayName = "ScheduleModal";
