// Lib
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
// Api
import { useGetKitchenInventoryItemDetailsQuery } from "rtkQuery/query/inventoryAPI";
// Hooks
import { useNotification, useTable, useViewport } from "hooks";
import { useAppSelector } from "hooks/redux";
// Selectors
import { getActiveKitchen } from "rtkQuery/selectors";
// Types
import { AdditionalFilter, ETable } from "types/tableFilters";
// Theme
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { DescriptionBlackIcon } from "icons";
// Layouts
// Components
import { ArrowBackLink, Pagination, Table } from "components";
// Styled
import { FlexContainer, PageWrapper } from "styled/Box";
import { Typography } from "styled/Typography";

import { columns, filtersList } from "./config";

export const KitchenInventoryDetails: FC = () => {
  const { isDesktop } = useViewport();
  const { menuId, id } = useParams();

  const kitchen = useAppSelector(getActiveKitchen);

  const { openNotification } = useNotification();

  const {
    orderDirection,
    orderBy,
    currentPage,
    pageSize,
    debouncedFiltersQuery,
    selectedFilters,
    handleSort,
    setCurrentPage,
    setPageSize,
    handleSetTableFilterValues,
  } = useTable({ name: ETable.InventoryDetails });

  const {
    data: tableData,
    isFetching,
    error,
  } = useGetKitchenInventoryItemDetailsQuery(
    {
      id,
      menuId,
      kitchenId: kitchen?.id,
      query: {
        ...debouncedFiltersQuery,
        page: currentPage,
        size: pageSize,
        orderBy: orderBy,
        orderDirection: orderDirection,
      },
    },
    { skip: !id || !kitchen?.id, refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    handleSort(sorter?.columnKey, sorter?.order);
  };

  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer
        $fullwidth
        $column
        $gap={20}
        $grow={1}
        $padding={isDesktop && "0 0 32px"}
      >
        <FlexContainer $column $fullwidth $gap={16} $align="flex-start">
          <ArrowBackLink
            title="Back to Inventory"
            path="/inventory/"
            state={{
              menuIdValue: menuId,
            }}
          />

          <Typography.H1>
            {tableData?.item?.product?.name &&
              `${tableData?.item?.product?.name} - Changes History`}
          </Typography.H1>
        </FlexContainer>

        <Table
          isLoading={isFetching}
          withPagination={!isDesktop}
          header={{
            totalCount: tableData?.total,
            filter: {
              include: [AdditionalFilter.Users],
              filters: filtersList,
              selected: selectedFilters || [],
              setValue: handleSetTableFilterValues,
            },
          }}
          empty={{
            icon: DescriptionBlackIcon,
            title: "No records to show.",
            description: "Try changing sections or change the filters",
          }}
          columns={columns}
          dataSource={tableData?.data || []}
          onChange={handleTableChange}
        />
      </FlexContainer>

      <Pagination
        onPage
        showSizeChanger={!isDesktop}
        padding="12px 16px"
        pageSize={pageSize}
        currentPage={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        totalItems={tableData?.total}
      />
    </PageWrapper>
  );
};
