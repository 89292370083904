// Lib
import { FC, useEffect } from "react";
// Api
import {
  useGetMenuItemsListAnalyticsQuery,
  useGetMenuItemsSummaryAnalyticsQuery,
} from "rtkQuery/query/analyticsAPI";
// Hooks
import { useExport, useNotification, useTable, useViewport } from "hooks";
import { useAppSelector } from "hooks/redux";
// Actions
// Selectors
import { getAnalyticsFilters, getUserCompanyId } from "rtkQuery/selectors";
// Types
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/es/table/interface";
import { ETable } from "types/tableFilters";
import { MenuItemListItem } from "types/analytics";
// Theme
import { theme } from "theme";
// Constants
import { queryParams } from "consts";
// Helpers
import { generateDates } from "../../helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { FileUploadIcon } from "icons";
// Components
import { Export, Pagination, Table } from "components";
import { Filters } from "../../components/Filters";
import { MainSummary } from "../components";
// Styled
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import { PageTitleContainer, PageWrapper } from "./styled";

import { columns } from "./config";

export const AnalyticsMenuItems: FC = () => {
  const companyId = useAppSelector(getUserCompanyId);

  const { isDesktop } = useViewport();

  const { openNotification } = useNotification();

  const { downloadCSV } = useExport();

  const {
    search,
    debouncedSearch,
    setSearch,
    pageSize,
    setPageSize,
    orderBy,
    orderDirection,
    currentPage,
    handleSort,
    setCurrentPage,
  } = useTable({ name: ETable.AnalyticsMenuItems });

  const analyticsFilters = useAppSelector(getAnalyticsFilters);

  const { current, prev, timezoneOffset } = generateDates(
    analyticsFilters.dateFilter,
  );

  const {
    data: summaryData,
    isFetching: isSummaryDataFetching,
    error: summaryDataError,
  } = useGetMenuItemsSummaryAnalyticsQuery(
    {
      companyId,
      query: {
        siteId: analyticsFilters.location,
        dateBefore: current.startTime,
        dateAfter: current.endTime,
        dateBeforePrev: prev.startTime,
        dateAfterPrev: prev.endTime,
        timezoneOffset,
      },
    },
    { ...queryParams, skip: !companyId },
  );

  const {
    data: menuList,
    isFetching: isMenuListFetching,
    error: menuListError,
  } = useGetMenuItemsListAnalyticsQuery(
    {
      companyId,
      query: {
        siteId: analyticsFilters.location,
        dateBefore: current.startTime,
        dateAfter: current.endTime,
        dateBeforePrev: prev.startTime,
        dateAfterPrev: prev.endTime,
        page: currentPage,
        size: pageSize,
        name: debouncedSearch,
        orderBy,
        orderDirection,
        timezoneOffset,
      },
    },
    { ...queryParams, skip: !companyId },
  );

  useEffect(() => {
    if (summaryDataError) {
      errorHandler({ error: summaryDataError, openNotification });
    }
  }, [summaryDataError]);

  useEffect(() => {
    if (menuListError) {
      errorHandler({ error: menuListError, openNotification });
    }
  }, [menuListError]);

  const getExportData = async (): Promise<Record<string, unknown>[]> => {
    try {
      //request here
      const data = menuList?.data as unknown as Record<string, unknown>[];

      return data;
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue>,
    sorter: SorterResult<MenuItemListItem>,
  ) => {
    handleSort(sorter?.field as string, sorter?.order);
  };

  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer
        $fullwidth
        $column
        $grow={1}
        $padding={isDesktop && "0 0 32px"}
      >
        <PageTitleContainer>
          <Typography.H1>Menu Items Analytics</Typography.H1>

          <Filters skipOrderStatus />
        </PageTitleContainer>

        <MainSummary isLoading={isSummaryDataFetching} data={summaryData} />

        <Table
          rowKey="menuItemId"
          isLoading={isMenuListFetching}
          dataSource={menuList?.data || []}
          columns={columns}
          onChange={handleTableChange}
          header={{
            leftTitle: "Product Sales",
            search: { value: search, setValue: setSearch },
            suffix: isDesktop ? (
              <Export
                type="Base"
                fileName="product_sales"
                isLoading={false}
                isDataLoading={false}
                isDisabled={false}
                columns={columns}
                getExportData={getExportData}
              />
            ) : (
              <Button.SquaredIcon
                icon={<FileUploadIcon fill={theme.color.text.tertiary} />}
                onClick={() =>
                  downloadCSV({
                    fileName: "product_sales",
                    isDataLoading: false,
                    columns: columns,
                    getExportData: getExportData,
                  })
                }
              />
            ),
          }}
        />
      </FlexContainer>

      <Pagination
        onPage
        padding="12px 16px"
        pageSize={pageSize}
        showSizeChanger={!isDesktop}
        currentPage={currentPage}
        setPageSize={setPageSize}
        totalItems={2}
        setCurrentPage={setCurrentPage}
      />
    </PageWrapper>
  );
};
