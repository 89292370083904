import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const BulletSuccess: FC<SvgIconConstituentValues> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      id="Bullet"
      cx="10"
      cy="10"
      r="8"
      fill="#5BAA5C"
      stroke="#A8D7B4"
      strokeWidth="4"
    />
  </svg>
);
