// Lib
import { FC, useEffect } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from "react-hook-form";
// Hooks
import { useViewport } from "hooks";
// Types
import { DayType } from "types/common";
import { OpeningHoursFormTypes } from "types/locations";
// Theme
import { theme } from "theme";
// Icons
import { CopyIcon, PlusIcon, TrashIcon } from "icons";
// Components
import { Badge, ButtonLink } from "components";
import { DatePicker, Switch } from "components/Form";
// Styled
import { ContentBox, FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";
import { Typography } from "styled/Typography";
import { FieldsContainer } from "./styled";

interface DayScheduleProps {
  title: string;
  disabled?: boolean;
  dayValue: DayType;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  control: Control<OpeningHoursFormTypes, any>;
  watch: UseFormWatch<OpeningHoursFormTypes>;
  setValue: UseFormSetValue<OpeningHoursFormTypes>;
  handleDuplicateValues: (args: DayType) => void;
}

export const DaySchedule: FC<DayScheduleProps> = ({
  title,
  disabled,
  dayValue,
  control,
  watch,
  setValue,
  handleDuplicateValues,
}) => {
  const { isLargeMobile, isMobile } = useViewport();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `days.${dayValue}.time`,
  });

  const handleDuplicate = () => {
    handleDuplicateValues(dayValue);
  };

  const switchChecked = watch(`days.${dayValue}.available`);

  useEffect(() => {
    if (!switchChecked) {
      setValue(`days.${dayValue}.time`, []);
    }
  }, [switchChecked]);

  return (
    <ContentBox $column>
      <FlexContainer $fullwidth $align="center" $justify="space-between">
        <Typography.H3>{title}</Typography.H3>

        <FlexContainer $gap={16} $align="center" $justify="center">
          {isMobile ? null : switchChecked ? (
            <ButtonLink
              icon={<PlusIcon fill={theme.color.blue} />}
              title="Add hours"
              disabled={disabled}
              onClick={() => append({ from: null, to: null })}
            />
          ) : (
            <Badge title="Closed" />
          )}

          <Controller
            name={`days.${dayValue}.available`}
            control={control}
            render={({ field }) => (
              <Switch checked={field.value} {...field} disabled={disabled} />
            )}
          />
        </FlexContainer>
      </FlexContainer>

      {!isMobile ? null : switchChecked ? (
        <FlexContainer $gap={8} $wrap>
          <Button.Base
            icon={<PlusIcon fill={theme.color.text.tertiary} />}
            $padding="8px"
            disabled={disabled}
            onClick={() => append({ from: null, to: null })}
          >
            Add hours
          </Button.Base>

          <Button.Base
            icon={<CopyIcon fill={theme.color.text.tertiary} />}
            $padding="8px"
            disabled={disabled}
            onClick={handleDuplicate}
          >
            Duplicate
          </Button.Base>
        </FlexContainer>
      ) : (
        <FlexContainer>
          <Badge title="Closed" />
        </FlexContainer>
      )}

      {fields?.length ? (
        fields?.map((timeField, timeIndex) => (
          <FieldsContainer
            key={timeField.id}
            $gap={isLargeMobile ? 8 : 16}
            $withmargin={timeIndex !== 0}
          >
            <Controller
              name={`days.${dayValue}.time.[${timeIndex}].from`}
              control={control}
              render={({ field, fieldState }) => (
                <DatePicker
                  picker="time"
                  format={"HH:mm"}
                  {...field}
                  fieldState={fieldState}
                  disabled={disabled}
                  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                  onChange={field.onChange as any}
                />
              )}
            />

            <FlexContainer $align="center" $justify="center">
              -
            </FlexContainer>

            <Controller
              name={`days.${dayValue}.time.[${timeIndex}].to`}
              control={control}
              render={({ field, fieldState }) => (
                <DatePicker
                  picker="time"
                  format={"HH:mm"}
                  {...field}
                  fieldState={fieldState}
                  disabled={disabled}
                  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                  onChange={field.onChange as any}
                />
              )}
            />

            <Button.SquaredIcon
              icon={<TrashIcon fill={theme.color.text.tertiary} />}
              disabled={disabled}
              onClick={() => remove(timeIndex)}
            />

            {!isMobile && timeIndex === 0 && (
              <>
                {isLargeMobile ? (
                  <Button.SquaredIcon
                    icon={<CopyIcon fill={theme.color.text.tertiary} />}
                    disabled={disabled}
                    onClick={handleDuplicate}
                  />
                ) : (
                  <Button.Base
                    icon={<CopyIcon fill={theme.color.text.tertiary} />}
                    $padding="8px"
                    disabled={disabled}
                    onClick={handleDuplicate}
                  >
                    Duplicate
                  </Button.Base>
                )}
              </>
            )}
          </FieldsContainer>
        ))
      ) : switchChecked ? (
        <Typography.Title>Open 24 h</Typography.Title>
      ) : null}
    </ContentBox>
  );
};
