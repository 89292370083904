// Lib
import { FC } from "react";
// Components
import { Filters } from "../../components";
import { CalendarTable, OrdersChart, RevenueBreakdown } from "../components";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { PageTitleContainer, PageWrapper } from "./styled";

export const AnalyticsOrders: FC = () => {
  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer $fullwidth $column $grow={1} $padding="0 0 32px">
        <PageTitleContainer>
          <Typography.H1>Order Analytics</Typography.H1>

          <Filters />
        </PageTitleContainer>

        <OrdersChart />

        <RevenueBreakdown />

        <CalendarTable />
      </FlexContainer>
    </PageWrapper>
  );
};
