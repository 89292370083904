// Lib
import { FC, useEffect, useState } from "react";
// Api
import {
  useCreateProductAlergentMutation,
  useCreateProductHighlightMutation,
  useDeleteProductAlergentMutation,
  useDeleteProductHighlightMutation,
  useGetProductAlergentsQuery,
  useGetProductHighlightsQuery,
  useUpdateProductAlergentMutation,
  useUpdateProductHighlightMutation,
} from "rtkQuery/query/productsAPI";
// Hooks
import { useNotification, usePermissions, useTable } from "hooks";
// Actions
// Selectors
// Types
import { TableAction } from "types/common";
import { ProductTag, TagType } from "types/products";
import { ETable } from "types/tableFilters";
// Theme
import { theme } from "theme";
// Constants
import { NOTIFICATIONS } from "consts";
// Helpers
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { PencilIcon, PlusIcon, TrashIcon } from "icons";
// Layouts
// Components
import { ConfirmDialog, Table } from "components";
import { TagCreateEditForm, TagCreateEditModal } from "../components";
// Styled
import { FlexContainer, PageButtonsContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import { PageWrapper } from "./styled";

import { columns, items } from "./config";

export const Tags: FC = () => {
  const { openNotification } = useNotification();

  const { canCreateTag, canUpdateTag, canDeleteTag } = usePermissions();

  const [tab, setTab] = useState(TagType.Highlights);
  const [deleteModal, setDeleteModal] = useState<ProductTag | false>(false);
  const [editModal, setEditModal] = useState<ProductTag | boolean>(false);

  const { setCurrentPage, handleSort } = useTable({
    name: ETable.Tags,
    removeQueryParams: true,
  });

  const [removeHighlight, { isLoading: isDeleteHighlightLoading }] =
    useDeleteProductHighlightMutation();
  const [createHighlight, { isLoading: isCreateHighlightLoading }] =
    useCreateProductHighlightMutation();
  const [updateHighlight, { isLoading: isUpdateHighlightLoading }] =
    useUpdateProductHighlightMutation();

  const [removeAlergent, { isLoading: isDeleteAlergentLoading }] =
    useDeleteProductAlergentMutation();
  const [createAlergent, { isLoading: isCreateAlergentLoading }] =
    useCreateProductAlergentMutation();
  const [updateAlergent, { isLoading: isUpdateAlergentLoading }] =
    useUpdateProductAlergentMutation();

  const request = {
    [TagType.Highlights]: {
      remove: removeHighlight,
      update: updateHighlight,
      create: createHighlight,
    },
    [TagType.Alergents]: {
      remove: removeAlergent,
      update: updateAlergent,
      create: createAlergent,
    },
  };

  const {
    data: highlightsData,
    isFetching: isHighlightsFetching,
    error: highlightsError,
  } = useGetProductHighlightsQuery(null, { refetchOnMountOrArgChange: true });

  const {
    data: alergentsData,
    isFetching: isAlergentsFetching,
    error: alergentsError,
  } = useGetProductAlergentsQuery(null, { refetchOnMountOrArgChange: true });

  const tableData = {
    [TagType.Highlights]: highlightsData || [],
    [TagType.Alergents]: alergentsData || [],
  };

  useEffect(() => {
    if (highlightsError) {
      errorHandler({ error: highlightsError, openNotification });
    }
  }, [highlightsError]);

  useEffect(() => {
    if (alergentsError) {
      errorHandler({ error: alergentsError, openNotification });
    }
  }, [alergentsError]);

  const handleSetTab = (value: string) => {
    setCurrentPage(1);
    setTab(value as TagType);
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const onConfirmDelete = async () => {
    if (!deleteModal) return;
    try {
      await request[tab].remove({ id: deleteModal?.id });

      setDeleteModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleSave = async ({
    id,
    formData,
  }: {
    id: string | null;
    formData: TagCreateEditForm;
  }) => {
    try {
      if (id) {
        await request[tab].update({ id, ...formData }).unwrap();
      } else {
        await request[tab].create({ ...formData, type: tab }).unwrap();
      }

      setEditModal(false);
    } catch (error) {
      errorHandler({ error: error.data, openNotification });
    }
  };

  const actions: TableAction[] = [
    {
      title: "",
      Icon: PencilIcon,
      disabled: !canUpdateTag,
      onClick: (tag: ProductTag) => setEditModal(tag),
    },
    {
      title: "",
      type: "Delete",
      Icon: TrashIcon,
      disabled: !canDeleteTag,
      onClick: (tag: ProductTag) => setDeleteModal(tag),
    },
  ];

  const isLoading = isHighlightsFetching || isAlergentsFetching;
  const isCreateLoading = isCreateHighlightLoading || isCreateAlergentLoading;
  const isUpdateLoading = isUpdateHighlightLoading || isUpdateAlergentLoading;
  const isDeleteLoading = isDeleteHighlightLoading || isDeleteAlergentLoading;

  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer
          $fullwidth
          $column
          $gap={20}
          $grow={1}
          $padding="0 0 32px"
        >
          <FlexContainer $align="center" $justify="space-between">
            <Typography.H1>Tags</Typography.H1>
            <PageButtonsContainer>
              {canCreateTag && (
                <Button.Heading
                  type="primary"
                  icon={<PlusIcon fill={theme.color.white} />}
                  onClick={() => setEditModal(true)}
                >
                  Add Tag
                </Button.Heading>
              )}
            </PageButtonsContainer>
          </FlexContainer>

          <Table
            isLoading={isLoading}
            rowKey={record => record.id}
            header={{
              tabs: { setTab: handleSetTab, items },
            }}
            accentColumn={2}
            accentColumnWidth="100%"
            columns={columns}
            dataSource={tableData?.[tab] || []}
            actions={actions}
            onChange={handleTableChange}
          />
        </FlexContainer>
      </PageWrapper>

      <TagCreateEditModal
        data={editModal}
        isLoading={isCreateLoading || isUpdateLoading}
        onClose={() => setEditModal(false)}
        onSave={handleSave}
      />

      <ConfirmDialog
        open={!!deleteModal}
        Icon={TrashIcon}
        message={deleteModal && `${deleteModal.name} will be deleted`}
        description={NOTIFICATIONS.APROVE_ACTION_MESSAGE}
        firstCTAButton={{
          title: "Delete Tag",
          status: "danger",
          loading: isDeleteLoading,
          onClick: () => onConfirmDelete(),
        }}
        onCancel={handleCloseDeleteModal}
      />
    </>
  );
};
