import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const LabelBlackIcon: FC<SvgIconConstituentValues> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Placeholder" clipPath="url(#clip0_3875_1758)">
      <path
        id="Vector"
        d="M17.63 5.84C17.27 5.33 16.67 5 16 5L5 5.01C3.9 5.01 3 5.9 3 7V17C3 18.1 3.9 18.99 5 18.99L16 19C16.67 19 17.27 18.67 17.63 18.16L22 12L17.63 5.84Z"
        fill="#646965"
      />
    </g>
    <defs>
      <clipPath id="clip0_3875_1758">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
