import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export type ErrorValue = string[] | string;

export type ErrorItems = Record<
  string,
  ErrorValue | Record<string, ErrorValue>[]
>;

export type GenericErrorData = {
  statusCode: number;
  timestamp: string;
  path: string;
  method: string;
  message: string;
};

export type SelectionItemErrors = Record<string, string[]>;

export type SectionErrors = Record<number, SelectionItemErrors>;

export type FieldsErrorData = GenericErrorData & {
  errors: Record<string, string[] | SectionErrors>;
};

export type GenericResponseError = {
  status: number;
  data: ErrorItems;
};

export type FieldsResponseError = {
  data: FieldsErrorData;
};

export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === "object" && error !== null && "status" in error;
}

export const isGenericResponseError = (
  error: unknown,
): error is GenericResponseError => {
  return (
    isFetchBaseQueryError(error) &&
    typeof error.data === "object" &&
    error.data !== null
  );
};

export const isErrorWithMessage = (
  error: unknown,
): error is { error: string } => {
  return (
    typeof error === "object" &&
    error !== null &&
    "error" in error &&
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    typeof (error as any).error === "string"
  );
};

export const isErrorWithMessageStringField = (
  error: unknown,
): error is { message: string } => {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    typeof (error as any).message === "string"
  );
};

export const isArrayOfString = (value: unknown): value is string[] => {
  return Array.isArray(value) && value.every(item => typeof item === "string");
};

export const isFieldsResponseError = (
  error: unknown,
): error is FieldsResponseError => {
  return (
    isFetchBaseQueryError(error) &&
    typeof error.data === "object" &&
    error.data != null &&
    "errors" in error.data
  );
};
