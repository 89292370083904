// Lib
import { FC, useCallback, useState } from "react";
// Api
// Hooks
// Actions
// Selectors
// Types
import {
  GetOperationReportDetailsResponse,
  OperationLogType,
} from "types/operationReport";
import { ListModalData } from "components/ListModal/types";
// Theme
// Constants
import { MONTH_DAY_YEAR_HOURS_MINUTES_DATE } from "consts";
// Helpers
import { dateTransform, getFullName } from "helpers/dataHelpers";
import {
  getOperationStatusBadge,
  getOperationTypeBadge,
} from "../../../helpers";
import { getAddress, getContacts } from "./helpers";
// Utils
// Icons
// Layouts
// Components
import { ListModal, SummaryField } from "components";
// Styled
import { ContentBox, SummaryItemsGrid } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";

interface SummaryProps {
  isScheduled?: boolean;
  isLoading: boolean;
  data: GetOperationReportDetailsResponse;
}

export const Summary: FC<SummaryProps> = ({ isLoading, data }) => {
  const [isListModal, setIsListModal] = useState<ListModalData | false>(false);

  const type = getOperationTypeBadge(data?.type);

  const status = getOperationStatusBadge(data?.status);

  const isMenuName =
    data?.type === OperationLogType.CREATE_MENU ||
    data?.type === OperationLogType.UPDATE_MENU ||
    data?.type === OperationLogType.PUBLISH_MENU ||
    data?.type === OperationLogType.SNOOZE_REPORT ||
    data?.type === OperationLogType.UN_SNOOZE_REPORT;

  const handleOpenListModal = useCallback((data: ListModalData) => {
    setIsListModal(data);
  }, []);

  const handleCloseListModal = useCallback(() => {
    setIsListModal(false);
  }, []);

  return (
    <>
      <ContentBox $column $gap={20}>
        <Typography.H2>Summary</Typography.H2>

        <SummaryItemsGrid>
          <SummaryField
            isLoading={isLoading}
            label="Report ID"
            value={data?.id}
          />

          <SummaryField
            isLoading={isLoading}
            label="Created At"
            value={dateTransform({
              date: data?.createdAt,
              format: MONTH_DAY_YEAR_HOURS_MINUTES_DATE,
            })}
          />

          <SummaryField isLoading={isLoading} label="Type" value={type} />

          <SummaryField isLoading={isLoading} label="Status" value={status} />

          <SummaryField
            isLoading={isLoading}
            label="User Initiated"
            value={data?.user?.fullName}
          />

          {data?.type === OperationLogType.CREATE_SITE ? (
            <SummaryField
              isLoading={isLoading}
              label="Location"
              value={data?.details?.locationName}
            />
          ) : (
            <SummaryField
              isLoading={isLoading}
              label="Location"
              value={
                !!data?.sites?.length && data?.sites.length > 1 ? (
                  <>
                    <span>{data?.sites[0]?.name}</span>

                    <span>
                      <Button.Notification
                        type="link"
                        onClick={() =>
                          handleOpenListModal({
                            title: "Locations",
                            list: data?.sites.map(site => site.name),
                          })
                        }
                      >{`+ ${
                        data?.sites.slice(1).length
                      } More`}</Button.Notification>
                    </span>
                  </>
                ) : (
                  data?.sites[0]?.name
                )
              }
            />
          )}

          <SummaryField
            isLoading={isLoading}
            label="Channel"
            value={data?.channel?.name}
          />

          {isMenuName && (
            <SummaryField
              isLoading={isLoading}
              label="Menu"
              value={data?.data?.name}
            />
          )}

          {data?.data?.summary?.products && (
            <>
              <SummaryField
                isLoading={isLoading}
                label="Products Inserted"
                value={(
                  data?.data?.summary?.products?.inserted || 0
                ).toString()}
              />

              <SummaryField
                isLoading={isLoading}
                label="Products Removed"
                value={(data?.data?.summary?.products?.removed || 0).toString()}
              />
            </>
          )}

          {data?.details?.activePOSEmployee && (
            <SummaryField
              isLoading={isLoading}
              label="Active Employee"
              value={getFullName({
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                firstName: (data?.details?.activePOSEmployee as any)?.firstName,
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                lastName: (data?.details?.activePOSEmployee as any)?.lastName,
              })}
            />
          )}

          {data?.type === OperationLogType.UPDATE_SITE && (
            <>
              <SummaryField
                isLoading={isLoading}
                label="Address"
                value={getAddress(data?.data)}
              />

              <SummaryField
                isLoading={isLoading}
                label="Contacts"
                value={getContacts(data?.data)}
              />
            </>
          )}
        </SummaryItemsGrid>
      </ContentBox>

      <ListModal data={isListModal} onClose={handleCloseListModal} />
    </>
  );
};
