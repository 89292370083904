// Lib
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Api
import {
  useLazyGetInventoryQuery,
  useUpdateInventoryMutation,
} from "rtkQuery/query/inventoryAPI";
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
import { useGetMenusQuery } from "rtkQuery/query/menusAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useNotification, usePermissions, useTable, useViewport } from "hooks";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { ETable } from "types/tableFilters";
import { InventoryItem, InventoryPropduct } from "types/inventory";
// Constants
import { NOTIFICATIONS } from "consts";
// Helpers
import { changeTableData } from "./helpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { DescriptionBlackIcon } from "icons";
// Components
import { Pagination, Status, Table } from "components";
import { Select } from "components/Form";
import { EditableCell } from "./components";
// Styled
import { FlexContainer, PageButtonsContainer, PageWrapper } from "styled/Box";
import { Typography } from "styled/Typography";
import { SelectContainer } from "./styled";

import { columns, items } from "./config";

export const Inventory: FC = () => {
  const { isDesktop, isMobile } = useViewport();

  const navigate = useNavigate();

  const location = useLocation();

  const companyId = useAppSelector(getUserCompanyId);

  const { openNotification } = useNotification();

  const { canUpdateInventory } = usePermissions();

  const {
    tab,
    orderBy,
    orderDirection,
    currentPage,
    pageSize,
    search,
    debouncedSearch,
    setCurrentPage,
    setPageSize,
    setSearch,
    handleSort,
    setTab,
  } = useTable({ name: ETable.Inventory });

  const [locationIdValue, setLocationIdValue] = useState<string>(
    () => location?.state?.locationIdValue || "",
  );
  const [menuIdValue, setMenuIdValue] = useState<string>(
    () => location?.state?.menuIdValue || "",
  );

  const [tableData, setTableData] = useState<InventoryItem[]>([]);

  const {
    data: locationsData,
    isLoading: isLocationsLoading,
    error: locationsError,
  } = useGetLocationsQuery({ companyId });

  const {
    data: menusListData,
    isLoading: isGetMenusLoading,
    error: menusError,
    refetch: refetchMenus,
  } = useGetMenusQuery(
    {
      companyId,
      query: {
        sites: locationIdValue,
      },
    },
    {
      skip: !companyId || !locationIdValue,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (locationsError) {
      errorHandler({ error: locationsError, openNotification });
    }
  }, [locationsError]);

  useEffect(() => {
    if (menusError) {
      errorHandler({ error: menusError, openNotification });
    }
  }, [menusError]);

  const [updateStock] = useUpdateInventoryMutation();

  const [
    getIventoryData,
    { isFetching: isInventoryLoading, data: inventoryData },
  ] = useLazyGetInventoryQuery();

  const fetchInventory = async () => {
    try {
      const data = await getIventoryData({
        menuId: menuIdValue,
        query: {
          orderBy,
          orderDirection,
          page: currentPage,
          size: pageSize,
          search: debouncedSearch,
          availability: tab,
        },
      }).unwrap();

      setTableData(data.data);
    } catch (error) {
      errorHandler({ error, openNotification });
      setTableData([]);
      setCurrentPage(1);
      setTab("");
    }
  };

  useEffect(() => {
    if (!menuIdValue) {
      return;
    }

    fetchInventory();
  }, [
    menuIdValue,
    orderBy,
    orderDirection,
    currentPage,
    pageSize,
    debouncedSearch,
    tab,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [tab]);

  useEffect(() => {
    if (!locationIdValue) return;

    refetchMenus();
  }, [locationIdValue]);

  const handleSetTab = (value: string) => {
    setTab(value);
  };

  const handleSetLocation = (value: string) => {
    setTableData([]);
    setMenuIdValue(null);
    setLocationIdValue(value);
  };

  const handleStockChange = async (record: InventoryItem, value: number) => {
    try {
      await updateStock({
        menuId: menuIdValue,
        menuItemId: record.id,
        data: { quantity: value },
      }).unwrap();

      setTableData(prev => changeTableData(prev, record?.id, value));

      openNotification({ message: NOTIFICATIONS.STOCK_UPDATED });
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const mappedColumns = columns.map(c => {
    if (c.key === "productName") {
      return {
        ...c,
        render: (product: InventoryPropduct, record: InventoryItem) => (
          <FlexContainer
            $gap={8}
            $align="center"
            $cursor="pointer"
            onClick={() =>
              navigate(
                `/loop/inventory/${locationIdValue}/${menuIdValue}/${record.id}`,
              )
            }
          >
            <Typography.Title>{product.name}</Typography.Title>

            {record.stockQuantity < 1 && (
              <Status text="Out of stock" status="danger" />
            )}
          </FlexContainer>
        ),
      };
    }

    if (c.key === "stock") {
      return {
        ...c,
        render: (quantity: number, record: InventoryItem) =>
          canUpdateInventory ? (
            <EditableCell
              initialValue={quantity}
              onCellValueChange={value => handleStockChange(record, value)}
            />
          ) : (
            quantity
          ),
      };
    }

    return c;
  });

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    handleSort(sorter?.columnKey, sorter?.order);
  };

  const locations =
    locationsData?.length &&
    locationsData.map(el => ({ label: el.name, value: el.id }));

  const menus =
    menusListData?.data?.length &&
    menusListData?.data?.map(el => ({ label: el.name, value: el.id }));

  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer
        $fullwidth
        $column
        $gap={20}
        $grow={1}
        $padding={isDesktop && "0 0 32px"}
      >
        <FlexContainer $wrap $gap={8} $align="center" $justify="space-between">
          <Typography.H1>Inventory</Typography.H1>

          <PageButtonsContainer
            $fullwidth={isMobile}
            $column={isMobile}
            $align={isMobile ? "stretch" : "flex-start"}
          >
            <SelectContainer>
              <Select
                label="Location"
                loading={isLocationsLoading}
                value={locationIdValue}
                options={locations}
                onChange={value => handleSetLocation(value)}
              />
            </SelectContainer>

            <SelectContainer>
              <Select
                label="Menu"
                loading={isGetMenusLoading || isLocationsLoading}
                disabled={isGetMenusLoading || isLocationsLoading}
                value={!isGetMenusLoading && menuIdValue}
                options={menus}
                onChange={value => setMenuIdValue(value)}
              />
            </SelectContainer>
          </PageButtonsContainer>
        </FlexContainer>

        <Table
          isLoading={isInventoryLoading}
          rowKey={record => record.id}
          header={{
            tabs: { tab, setTab: handleSetTab, items },
            search: { value: search, setValue: setSearch },
          }}
          empty={{
            icon: DescriptionBlackIcon,
            title: "No items to show.",
            description:
              "Try changing Location and Menu, or change the filters",
          }}
          columns={mappedColumns}
          dataSource={tableData || []}
          onChange={handleTableChange}
        />
      </FlexContainer>

      <Pagination
        onPage
        padding="12px 16px"
        pageSize={pageSize}
        currentPage={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        totalItems={inventoryData?.total}
      />
    </PageWrapper>
  );
};
