// Lib
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
// Api
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
import {
  useGetOperationReportsQuery,
  useLazyGetExportSnoozeReportQuery,
} from "rtkQuery/query/operationReportsAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import {
  useExport,
  useNotification,
  usePermissions,
  useTable,
  useViewport,
} from "hooks";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { ETable } from "types/tableFilters";
import {
  OperationLogStatusLabel,
  OperationLogTypeLabel,
  OperationReportDto,
  OperationReportFilterTypes,
} from "types/operationReport";
import { ListModalData } from "components/ListModal/types";
// Constants
import { MONTH_DAY_YEAR_HOURS_MINUTES_DATE, queryParams } from "consts";
// Helpers
import { dateTransform, getSnoozeEndDate } from "helpers/dataHelpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { RightBurgerMenuIcon } from "icons";
// Components
import {
  DropDown,
  ExportDropDown,
  ListModal,
  Pagination,
  Table,
} from "components";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import {
  ButtonsContainer,
  PageTitleContainer,
  PageWrapper,
  Refresh,
} from "./styled";

import { createColumns, exportSnoozeColumns, filtersList } from "./config";

export const OperationsReport: FC = () => {
  const { isDesktop } = useViewport();

  const { downloadCSV } = useExport();
  const navigate = useNavigate();

  const { canReadOperationReport } = usePermissions();

  const companyId = useAppSelector(getUserCompanyId);

  const { openNotification } = useNotification();

  const [isListModal, setIsListModal] = useState<ListModalData | false>(false);

  const {
    orderBy,
    orderDirection,
    pageSize,
    currentPage,
    selectedFilters,
    debouncedFiltersQuery,
    setCurrentPage,
    setPageSize,
    handleSort,
    handleSetTableFilterValues,
  } = useTable({ name: ETable.OperationReports });

  const {
    data: locationsData,
    isError: isLocationsError,
    error: locationsError,
  } = useGetLocationsQuery(
    { companyId },
    { skip: !companyId, refetchOnMountOrArgChange: true },
  );

  const { data, isFetching, error, refetch } = useGetOperationReportsQuery(
    {
      query: {
        page: currentPage,
        size: pageSize,
        orderBy,
        orderDirection,
        ...debouncedFiltersQuery,
      },
    },
    queryParams,
  );

  const [getSnoozeReport, { isLoading }] = useLazyGetExportSnoozeReportQuery();

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  useEffect(() => {
    if (isLocationsError) {
      errorHandler({ error: locationsError, openNotification });
    }
  }, [isLocationsError]);

  const getExportSnoozeData = async (): Promise<Record<string, unknown>[]> => {
    try {
      const data = await getSnoozeReport({
        query: {
          orderBy,
          orderDirection,
          statuses: debouncedFiltersQuery?.statuses,
          sites: debouncedFiltersQuery?.sites,
          dateBefore: debouncedFiltersQuery?.dateBefore,
          dateAfter: debouncedFiltersQuery?.dateAfter,
        },
      }).unwrap();

      return data.map(record => ({
        ...record,
        type: OperationLogTypeLabel[record.type],
        status: OperationLogStatusLabel[record.status],
        operationStart: dateTransform({
          date: record.operationStart,
          format: MONTH_DAY_YEAR_HOURS_MINUTES_DATE,
        }),
        snoozeStart: dateTransform({
          date: record?.snoozeStart,
          format: MONTH_DAY_YEAR_HOURS_MINUTES_DATE,
        }),
        snoozeEnd: getSnoozeEndDate(record?.snoozeEnd),
      }));
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleOpenListModal = useCallback((data: ListModalData) => {
    setIsListModal(data);
  }, []);

  const handleCloseListModal = useCallback(() => {
    setIsListModal(false);
  }, []);

  const columns = useMemo(
    () => createColumns({ handleOpenListModal }),
    [handleOpenListModal],
  );

  const onRow = useCallback((record: OperationReportDto) => {
    return {
      onClick: () => {
        navigate(`/loop/operations/operations_report/${record?.id}`);
      },
    };
  }, []);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    handleSort(sorter?.columnKey, sorter?.order);
  };

  const filtersData = useMemo(
    () =>
      locationsData
        ? filtersList.map(el =>
            el.key === OperationReportFilterTypes.sites
              ? {
                  ...el,
                  options: locationsData.map(l => ({
                    label: l.name,
                    type: l.id,
                  })),
                }
              : el,
          )
        : filtersList,
    [locationsData],
  );

  const dropDownMenuItems = [
    {
      key: "2",
      label: "Refresh",
      onClick: refetch,
    },
    {
      key: "3",
      label: "Export Snooze reports",
      onClick: () =>
        downloadCSV({
          fileName: "snooze_reports",
          isDataLoading: isLoading,
          columns: exportSnoozeColumns,
          getExportData: getExportSnoozeData,
        }),
    },
  ];

  return (
    <PageWrapper $fullwidth $column>
      <FlexContainer
        $fullwidth
        $column
        $grow={1}
        $padding={isDesktop && "0 0 32px"}
      >
        <PageTitleContainer>
          <Typography.H1>Operation Report</Typography.H1>

          {isDesktop ? (
            <ButtonsContainer>
              <ExportDropDown
                isLoading={isLoading}
                isDataLoading={isLoading}
                isDisabled={isLoading}
                items={[
                  {
                    key: "1",
                    label: "Snooze Reports",
                    fileName: "snooze_reports",
                    columns: exportSnoozeColumns,
                    getExportData: getExportSnoozeData,
                  },
                ]}
              />

              <Button.Heading icon={<Refresh />} onClick={() => refetch()}>
                Refresh
              </Button.Heading>
            </ButtonsContainer>
          ) : (
            <DropDown trigger={["click"]} items={dropDownMenuItems}>
              <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
            </DropDown>
          )}
        </PageTitleContainer>

        <Table
          isLoading={isFetching}
          header={{
            totalCount: data?.total,
            filter: {
              filters: filtersData,
              selected: selectedFilters,
              setValue: handleSetTableFilterValues,
            },
          }}
          dataSource={data?.data || []}
          columns={columns}
          onChange={handleTableChange}
          onRow={canReadOperationReport && onRow}
        />
      </FlexContainer>

      <Pagination
        onPage
        padding="12px 16px"
        pageSize={pageSize}
        showSizeChanger={!isDesktop}
        currentPage={currentPage}
        totalItems={data?.total}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
      />

      <ListModal data={isListModal} onClose={handleCloseListModal} />
    </PageWrapper>
  );
};
