// Lib
import { FC, Fragment, memo, useEffect, useMemo, useState } from "react";
import { Spin } from "antd";
// Api
import {
  useGetOrdersChartAnalyticsQuery,
  useGetOrdersSummaryAnalyticsQuery,
} from "rtkQuery/query/analyticsAPI";
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
// Selectors
import { getAnalyticsFilters, getUserCompanyId } from "rtkQuery/selectors";
// Types
import { OrderStatus } from "types/orders";
import { AnalyticsStatsSummaryKey } from "types/analytics";
// Consts
import { queryParams } from "consts";
// Helpers
import {
  createButtons,
  createChartData,
  generateDates,
} from "../../../helpers";
import { createChartConfig, createOrderTabs } from "./helpers";
// Components
import { Loader } from "components";
import { ChartButton, LineChart } from "components/Chart";
// Styled
import { ButtonsWrapper, Devider, Wrapper } from "./styled";

export const OrdersChart: FC = memo(() => {
  const companyId = useAppSelector(getUserCompanyId);
  const analyticsFilters = useAppSelector(getAnalyticsFilters);

  const [selected, setSelected] = useState<AnalyticsStatsSummaryKey | null>(
    null,
  );

  const { current, prev, timezoneOffset, groupBy } = generateDates(
    analyticsFilters.dateFilter,
  );

  const { data: locationsData } = useGetLocationsQuery({ companyId });

  const { data: summaryData, isFetching: isSummaryFetching } =
    useGetOrdersSummaryAnalyticsQuery(
      {
        companyId,
        query: {
          deliveredOnly: analyticsFilters.orderStatus === OrderStatus.DELIVERED,
          siteId: analyticsFilters.location,
          dateBefore: current.startTime,
          dateAfter: current.endTime,
          dateBeforePrev: prev.startTime,
          dateAfterPrev: prev.endTime,
          timezoneOffset,
        },
      },
      { ...queryParams, skip: !companyId },
    );

  const { data: chartData, isFetching: isChartFetching } =
    useGetOrdersChartAnalyticsQuery(
      {
        companyId,
        query: {
          deliveredOnly: analyticsFilters.orderStatus === OrderStatus.DELIVERED,
          siteId: analyticsFilters.location,
          dateBefore: current.startTime,
          dateAfter: current.endTime,
          dateBeforePrev: prev.startTime,
          dateAfterPrev: prev.endTime,
          compare: analyticsFilters.comparing,
          period: analyticsFilters.dateFilter.value,
          timezoneOffset,
          groupBy,
        },
      },
      { ...queryParams, skip: !companyId },
    );

  console.log("chartData", chartData);

  const tabs = useMemo(() => createOrderTabs(summaryData), [summaryData]);

  const statsButtons = useMemo(() => createButtons(tabs), [tabs]);

  useEffect(() => {
    if (!!tabs?.length && !selected) {
      setSelected(tabs[0]?.dataKey);
    }
  }, [tabs]);

  const locations = useMemo(
    () =>
      locationsData?.map(({ name, id }) => ({ label: name, value: id })) || [],
    [locationsData],
  );

  const chartValuesData = useMemo(
    () =>
      createChartData(chartData?.data?.slotsData || [], locations, selected),
    [chartData?.data?.slotsData, locations, selected],
  );

  const { legendConfig, linesConfig } = useMemo(
    () =>
      createChartConfig({
        locations,
        activeLocationId: analyticsFilters.location,
        analyticsFilters,
      }),
    [analyticsFilters.location, locations, chartData?.data?.slotsData],
  );

  const isLoading = isSummaryFetching || isChartFetching;

  return (
    <Spin
      spinning={isLoading}
      indicator={
        <>
          <Loader />
        </>
      }
    >
      <Wrapper>
        <ButtonsWrapper>
          {statsButtons?.map((button, i, array) => (
            <Fragment key={button.statsKey}>
              <ChartButton
                statsKey={button.statsKey}
                title={button.title}
                profitDisplay={button.profitDisplay}
                statsValue={button.statsValue}
                statsPrevValue={button.statsPrevValue}
                statsValuePrefix={button.statsValuePrefix}
                isSelected={button.statsKey === selected}
                isInvertedProfit={button.isInvertedProfit}
                onClick={setSelected}
              />
              {i !== array?.length - 1 && <Devider className="devider" />}
            </Fragment>
          ))}
        </ButtonsWrapper>

        <LineChart
          legendConfig={legendConfig}
          linesConfig={linesConfig}
          chartData={chartValuesData}
        />
      </Wrapper>
    </Spin>
  );
});

OrdersChart.displayName = "OrdersChart";
