// Lib
import { FC, memo, useState } from "react";
// Api
import { useGetOrderRevenueBreakdownAnalyticsQuery } from "rtkQuery/query/analyticsAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useViewport } from "hooks";
// Selectors
import { getAnalyticsFilters, getUserCompanyId } from "rtkQuery/selectors";
// Constants
import { queryParams } from "consts";
// Helpers
import { generateDates } from "pages/Loop/Analytics/helpers";
// Components
import { Pagination, Table } from "components";
import { Select } from "components/Form";
// Styled
import { TableShadowContainer } from "./styled";

import { createColumns, revenueOptions } from "./config";

export const RevenueBreakdown: FC = memo(() => {
  const { isDesktop } = useViewport();
  const companyId = useAppSelector(getUserCompanyId);
  const analyticsFilters = useAppSelector(getAnalyticsFilters);

  const [period, setPeriod] = useState<"month" | "day">("month");
  const [pageSize, setPageSize] = useState<10 | 50 | 25 | number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { current, timezoneOffset } = generateDates(
    analyticsFilters.dateFilter,
  );

  const { data, isFetching } = useGetOrderRevenueBreakdownAnalyticsQuery(
    {
      companyId,
      query: {
        period,
        siteId: analyticsFilters.location,
        dateBefore: current.startTime,
        dateAfter: current.endTime,
        timezoneOffset,
      },
    },
    queryParams,
  );

  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;

  const currentItems = data?.items.slice(indexOfFirstItem, indexOfLastItem);

  const columns = createColumns(period);

  return (
    <TableShadowContainer>
      <Table
        rowKey="period"
        shadow={false}
        isLoading={isFetching}
        withPagination={true}
        dataSource={currentItems || []}
        columns={columns}
        header={{
          leftTitle: "Revenue Breakdown",
          suffix: (
            <Select
              style={{ width: 100 }}
              value={period}
              onChange={v => setPeriod(v)}
              options={revenueOptions}
            />
          ),
        }}
      />

      <Pagination
        padding="12px 16px"
        pageSize={pageSize}
        showSizeChanger={!isDesktop}
        currentPage={currentPage}
        setPageSize={setPageSize}
        totalItems={data?.items?.length || 0}
        setCurrentPage={setCurrentPage}
      />
    </TableShadowContainer>
  );
});

RevenueBreakdown.displayName = "RevenueBreakdown";
