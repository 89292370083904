// Lib
import { FC } from "react";
import { Skeleton } from "antd";
// Styled
import { Typography } from "styled/Typography";
import { Wrapper } from "./styled";

interface StatisticsSummaryProps {
  isLoading?: boolean;
  title: string;
  text: string | number;
}

export const StatisticsSummary: FC<StatisticsSummaryProps> = ({
  isLoading,
  title,
  text,
}) => {
  return (
    <Wrapper>
      <Typography.TitleThin>{title}</Typography.TitleThin>
      {isLoading ? (
        <Skeleton.Input active size="large" />
      ) : (
        <Typography.H2>{text}</Typography.H2>
      )}
    </Wrapper>
  );
};
