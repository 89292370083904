import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const CreditCardIcon: FC<SvgIconConstituentValues> = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="credit_card_black_24dp 1" clipPath="url(#clip0_274_41607)">
      <path
        id="Vector"
        d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM20 8H4V6H20V8Z"
        fill={fill || "#646965"}
      />
    </g>
    <defs>
      <clipPath id="clip0_274_41607">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
