import { Link } from "react-router-dom";
import { MenuItemListItem } from "types/analytics";

export const columns = [
  {
    title: "Menu Item",
    dataIndex: "menuItemName",
    key: "menuItemName",
    sorter: true,
    render: (text: string, record: MenuItemListItem) => (
      <Link to={`/loop/analytics/menus-analytics/${record.menuItemId}`}>
        {" "}
        {text}
      </Link>
    ),
  },
  {
    title: "Total Sold",
    dataIndex: "totalSold",
    key: "totalSold",
    sorter: true,
  },
  {
    title: "Items sold individually",
    dataIndex: "individualSold",
    key: "individualSold",
    sorter: true,
  },
  {
    title: "Revenue Base",
    dataIndex: "revenueBase",
    key: "revenueBase",
    sorter: true,
    render: (text: string) => `KWD ${text}`,
  },
];
