// Lib
import { FC } from "react";
import { useNavigate } from "react-router-dom";
// Api
import { useCreateLocationAddressMutation } from "rtkQuery/query/locationsAPI";
// Hooks
import { useNotification, usePermissions } from "hooks";
import { useAppSelector } from "hooks/redux";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { StatusType } from "types/common";
import { LocationAddressFormSubmitData } from "types/locations";
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS } from "consts";
// Components
import { ArrowBackLink, Loader } from "components";
import { EditLocationForm } from "../components";
// Utils
import { refreshAccessToken } from "utils/refreshAccessToken";
import { errorHandler } from "utils/errorHandler";
// Styled
import { Typography } from "styled/Typography";
import {
  FlexContainer,
  PageHeadingContainer,
  PageTitleContainer,
} from "styled/Box";

export const AddLocation: FC = () => {
  const navigate = useNavigate();

  const companyId = useAppSelector(getUserCompanyId);

  const { openNotification } = useNotification();

  const { canCreateSite } = usePermissions();

  if (!canCreateSite) {
    navigate(ADMIN_ROUTES.LOCATIONS.path);
  }

  const [createLocation, { isLoading }] = useCreateLocationAddressMutation();

  const handleSendRequest = async (values: LocationAddressFormSubmitData) => {
    const { isHomeSite, ...formValues } = values;

    const emptyDayData: {
      available: boolean;
      time: never[];
    } = {
      available: false,
      time: [],
    };

    const data = {
      name: formValues.name,
      isHomeSite: isHomeSite,
      status: StatusType.Active,
      workingHours: {
        monday: emptyDayData,
        tuesday: emptyDayData,
        wednesday: emptyDayData,
        thursday: emptyDayData,
        friday: emptyDayData,
        saturday: emptyDayData,
        sunday: emptyDayData,
      },
      address: {
        ...formValues,
      },
    };

    try {
      await createLocation({ companyId, data }).unwrap();

      //important to refresh token to get new isHomeSite value
      await refreshAccessToken();

      openNotification({ message: NOTIFICATIONS.LOCATION_ADDRESS_ADDED });

      navigate(-1);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <FlexContainer $column $gap={24}>
        <PageHeadingContainer>
          <PageTitleContainer $column $gap={16}>
            <ArrowBackLink
              title="Back to Location"
              path={ADMIN_ROUTES.LOCATIONS.path}
            />

            <Typography.H1>Add Location</Typography.H1>
          </PageTitleContainer>
        </PageHeadingContainer>

        <EditLocationForm handleSendRequest={handleSendRequest} />
      </FlexContainer>
    </>
  );
};
