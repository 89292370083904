import {
  FilterField,
  FilterFieldDropdownOption,
  FilterOption,
} from "types/tableFilters";
import {
  OperationLogStatus,
  OperationLogStatusLabel,
  OperationReportFilterNames,
  OperationReportFilterTypes,
  OperationReportChannel,
  OperationLogType,
  OperationLogTypeLabel,
} from "types/operationReport";
import { EFilterDateLabel, EFilterDateValue } from "types/common";

import { MONTH_DAY_YEAR_HOURS_MINUTES_DATE } from "consts";

import { dateTransform } from "helpers/dataHelpers";
import { getOperationStatusBadge, getOperationTypeBadge } from "../helpers";
import { ReactNode } from "react";
import { FlexContainer } from "styled/Box";
import { TableRowButton } from "./styled";
import { CreateCellValues, createColumnsValues } from "./types";

export const columns = [
  {
    title: "Operation start",
    dataIndex: "createdAt",
    key: "createdAt",
    width: "220px",
    sorter: true,
    render: (date: string) =>
      dateTransform({
        date,
        format: MONTH_DAY_YEAR_HOURS_MINUTES_DATE,
      }),
  },
  {
    title: "Operation type",
    dataIndex: "type",
    key: "type",
    sorter: true,
    render: (type: string) => getOperationTypeBadge(type),
  },
  {
    title: "Location",
    dataIndex: "sites",
    key: "siteName",
    sorter: true,
  },
  {
    title: "Channel",
    dataIndex: "channel",
    key: "channel",
    render: (channel: OperationReportChannel) => channel?.name,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: true,
    render: status => getOperationStatusBadge(status),
  },
];

const createCell = ({
  title,
  data,
  handleOpenListModal,
}: CreateCellValues): ReactNode => {
  if (!data || !data.length) {
    return null;
  }

  if (data.length === 1) {
    return data[0]?.name || null;
  }

  if (data.length > 1) {
    const list = data?.map(el => el?.name);
    const otherElements = data.slice(1);
    return (
      <FlexContainer $gap={4} $align="center">
        <span>{data[0]?.name}</span>
        <TableRowButton
          type="link"
          onClick={e => {
            e.stopPropagation();
            handleOpenListModal({ title, list });
          }}
        >{`+ ${otherElements.length} More`}</TableRowButton>
      </FlexContainer>
    );
  }
};

export const createColumns = ({ handleOpenListModal }: createColumnsValues) => {
  return columns.map(column => {
    if (column?.key === "siteName") {
      return {
        ...column,
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        render: (data: any[]) =>
          createCell({ title: "Locations", data, handleOpenListModal }),
      };
    }

    return column;
  });
};

export const dateTimeDropdownFields: FilterFieldDropdownOption[] = [
  {
    type: EFilterDateValue.TODAY,
    label: EFilterDateLabel[EFilterDateValue.TODAY],
  },
  {
    type: EFilterDateValue.YESTERDAY,
    label: EFilterDateLabel[EFilterDateValue.YESTERDAY],
  },
  {
    type: EFilterDateValue.IN_THE_LAST_7_DAYS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_LAST_7_DAYS],
  },
  {
    type: EFilterDateValue.IN_THE_LAST_30_DAYS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_LAST_30_DAYS],
  },
  {
    type: EFilterDateValue.IN_THE_LAST_90_DAYS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_LAST_90_DAYS],
  },
  {
    type: EFilterDateValue.IN_THE_12_MONTHS,
    label: EFilterDateLabel[EFilterDateValue.IN_THE_12_MONTHS],
  },
  {
    type: EFilterDateValue.CUSTOM,
    label: EFilterDateLabel[EFilterDateValue.CUSTOM],
  },
];

export const exportSnoozeColumns = [
  {
    title: "Operation start",
    dataIndex: "operationStart",
    key: "operationStart",
  },
  {
    title: "Name",
    dataIndex: "itemName",
    key: "itemName",
  },
  {
    title: "PLU",
    dataIndex: "plu",
    key: "plu",
  },
  {
    title: "Operation type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Location",
    dataIndex: "siteName",
    key: "siteName",
  },
  {
    title: "Channel",
    dataIndex: "channelName",
    key: "channelName",
  },
  {
    title: "Snooze start",
    dataIndex: "snoozeStart",
    key: "snoozeStart",
  },
  {
    title: "Snooze end",
    dataIndex: "snoozeEnd",
    key: "snoozeEnd",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];

const dropdownTypeOptions: FilterFieldDropdownOption[] = Object.values(
  OperationLogType,
)
  .filter(
    type =>
      type !== OperationLogType.PRODUCT_SNOOZE &&
      type !== OperationLogType.PRODUCT_UNSNOOZE,
  )
  .map(type => ({ type, label: OperationLogTypeLabel[type] }));

export const dropdownStatusOptions: FilterFieldDropdownOption[] = [
  {
    type: OperationLogStatus.SUCCESS,
    label: OperationLogStatusLabel[OperationLogStatus.SUCCESS],
  },
  {
    type: OperationLogStatus.FAILED,
    label: OperationLogStatusLabel[OperationLogStatus.FAILED],
  },
  {
    type: OperationLogStatus.UNKNOWN,
    label: OperationLogStatusLabel[OperationLogStatus.UNKNOWN],
  },
];

// TODO: change options to arrays you need
export const filtersList: FilterOption[] = [
  {
    key: OperationReportFilterTypes.date,
    label: OperationReportFilterNames[OperationReportFilterTypes.date],
    field: FilterField.DATE_RANGE,
    options: dateTimeDropdownFields,
  },
  {
    key: OperationReportFilterTypes.types,
    label: OperationReportFilterNames[OperationReportFilterTypes.types],
    field: FilterField.MULTISELECT,
    options: dropdownTypeOptions,
  },
  {
    key: OperationReportFilterTypes.sites,
    label: OperationReportFilterNames[OperationReportFilterTypes.sites],
    field: FilterField.MULTISELECT,
    options: [],
  },
  {
    key: OperationReportFilterTypes.statuses,
    label: OperationReportFilterNames[OperationReportFilterTypes.statuses],
    field: FilterField.MULTISELECT,
    options: dropdownStatusOptions,
  },
];
