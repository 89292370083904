import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const ExpandCircleIcon: FC<SvgIconConstituentValues> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="expand_circle_down_black_24dp 1" clipPath="url(#clip0_2932_4847)">
      <path
        id="Vector"
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM15.79 11.71L12.71 14.79C12.32 15.18 11.69 15.18 11.29 14.79L8.21 11.71C7.82 11.32 7.82 10.68 8.21 10.29C8.6 9.9 9.23 9.9 9.62 10.29L12 12.67L14.38 10.29C14.77 9.9 15.4 9.9 15.79 10.29C16.18 10.68 16.18 11.32 15.79 11.71Z"
        fill="#646965"
      />
    </g>
    <defs>
      <clipPath id="clip0_2932_4847">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
