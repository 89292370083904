// Lib
import { FC, memo } from "react";
// Types
import { GetMenuItemDetailsSummaryAnalyticsResponse } from "types/analytics";
// Components
import { StatisticsSummary } from "../../../components";
// Styled
import { StaticsGrid } from "./styled";

interface DetailedSummaryProps {
  isLoading: boolean;
  data: GetMenuItemDetailsSummaryAnalyticsResponse;
}

export const DetailedSummary: FC<DetailedSummaryProps> = memo(
  ({ isLoading, data }) => {
    return (
      <StaticsGrid>
        <StatisticsSummary
          isLoading={isLoading}
          title="Total items sold"
          text={data?.totalSold}
        />

        <StatisticsSummary
          isLoading={isLoading}
          title="Total revenue"
          text={data?.totalRevenue}
        />

        <StatisticsSummary
          isLoading={isLoading}
          title="Most popular order day"
          text={data?.popularDay}
        />
        <StatisticsSummary
          isLoading={isLoading}
          title="Most popular order time"
          text={data?.popularTime}
        />
      </StaticsGrid>
    );
  },
);

DetailedSummary.displayName = "DetailedSummary";
