// Lib
import { FC, useEffect, useState } from "react";
// Api
import { useGetProductsQuery } from "rtkQuery/query/productsAPI";
// Hooks
import { useNotification, useTable, useViewport } from "hooks";
import { useAppSelector } from "hooks/redux";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { ETable } from "types/tableFilters";
import { Product } from "types/products";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { SearchBlackIcon } from "icons";
// Components
import { Modal, Pagination, Table } from "components";
import { Input } from "components/Form";
// Styled
import { Button } from "styled/Buttons";
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";

import { columns } from "./config";

interface AddItemsModalProps {
  open: boolean;
  locationId: string;
  disabledProductsIds: string[];
  category:
    | false
    | {
        categoryId: string;
        categoryName: string;
      };
  handleAddItemsToCategory: (categoryId: string, items: Product[]) => void;
  handleClose: () => void;
}

export const AddItemsModal: FC<AddItemsModalProps> = ({
  open,
  locationId,
  disabledProductsIds,
  category,
  handleAddItemsToCategory,
  handleClose,
}) => {
  const { isMobile, isLargeMobile } = useViewport();

  const { openNotification } = useNotification();

  const companyId = useAppSelector(getUserCompanyId);

  const {
    orderDirection,
    orderBy,
    currentPage,
    pageSize,
    search,
    debouncedSearch,
    setCurrentPage,
    setPageSize,
    setSearch,
    handleSort,
  } = useTable({ name: ETable.AddProductsModal, removeQueryParams: true });

  const {
    data: tableData,
    isFetching: isTableDataFetching,
    isError,
    error,
  } = useGetProductsQuery(
    {
      companyId,
      locationId,
      query: {
        page: currentPage,
        size: pageSize,
        orderBy: orderBy,
        orderDirection: orderDirection,
        name: debouncedSearch,
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !companyId || !locationId,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    },
  );

  useEffect(() => {
    if (isError) {
      errorHandler({ error, openNotification });
    }
  }, [isError]);

  const [selected, setSelected] = useState<Product[]>([]);

  useEffect(() => {
    if (!open) {
      setSelected([]);
    }
  }, [open]);

  const rowSelection = {
    getCheckboxProps: (record: Product) => ({
      disabled: disabledProductsIds.includes(record.id),
    }),
    onSelect: (record: Product, selected: boolean) => {
      if (selected) {
        setSelected(prev => [...prev, record]);
      } else {
        setSelected(prev => prev.filter(p => p.id !== record.id));
      }
    },
    onSelectAll: (
      selected: boolean,
      _selectedRows: Product[],
      changeRows: Product[],
    ) => {
      if (selected) {
        setSelected(prev => prev.concat(changeRows));
      } else {
        setSelected(prev =>
          prev.filter(p => !changeRows.find(r => r.id === p.id)),
        );
      }
    },
  };

  const addSelectedItems = () => {
    if (typeof category !== "boolean") {
      handleAddItemsToCategory(category.categoryId, selected);
    }
  };

  const onClose = () => {
    setSelected([]);
    handleClose();
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const title = `Add item to ${category ? category?.categoryName : "category"}`;

  return (
    <Modal title={title} open={open} width={1150} onClose={onClose}>
      <FlexContainer $fullwidth $column $gap={20}>
        <FlexContainer
          $fullwidth={isLargeMobile}
          $column={isLargeMobile}
          $gap={8}
          $align={isLargeMobile ? "stretch" : "center"}
          $justify="space-between"
        >
          <Typography.TitleThin>
            Showing {tableData?.data?.length || 0} of {tableData?.total} items
          </Typography.TitleThin>

          <Input
            prefix={<SearchBlackIcon />}
            placeholder="Search menu"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </FlexContainer>

        <Table
          rowKey={record => record.id}
          grayRowsIds={disabledProductsIds}
          isLoading={isTableDataFetching}
          wrap={isLargeMobile}
          accentColumn={3}
          columns={columns}
          dataSource={tableData?.data}
          scroll={{ y: "55vh", x: isLargeMobile && "150vw" }}
          rowSelection={{
            selectedRowKeys: selected.map(el => el.id),
            type: "checkbox",
            ...rowSelection,
          }}
          onChange={handleTableChange}
        />

        <Pagination
          padding="12px 0"
          pageSize={pageSize}
          currentPage={currentPage}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          totalItems={tableData?.total}
        />
      </FlexContainer>

      <FlexContainer
        $fullwidth
        $column={isMobile}
        $justify="flex-end"
        $gap={8}
        $padding="24px 0 0"
      >
        <Button.Heading $fullWidth={isMobile} onClick={onClose}>
          Cancel
        </Button.Heading>

        <Button.Heading
          type="primary"
          $fullWidth={isMobile}
          disabled={!selected.length}
          onClick={addSelectedItems}
        >
          Add Product
        </Button.Heading>
      </FlexContainer>
    </Modal>
  );
};
