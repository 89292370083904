import {
  AnalyticsStatsSummaryKey,
  GetMenuItemDetailsSummaryAnalyticsResponse,
} from "types/analytics";

export const createItemsChartTabs = (
  data: GetMenuItemDetailsSummaryAnalyticsResponse,
) => {
  if (!data) {
    return [];
  }

  return [
    {
      dataKey: AnalyticsStatsSummaryKey.Revenue,
      value: Number(data.current.totalRevenue),
      prevValue: Number(data.prev.totalRevenue),
    },
    {
      dataKey: AnalyticsStatsSummaryKey.ItemSold,
      value: Number(data.current.totalSold),
      prevValue: Number(data.prev.totalSold),
    },
  ];
};
