import { styled } from "styled-components";

export const StaticsGrid = styled.div`
  display: grid;
  gap: 16px;
  margin-bottom: 16px;

  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 950px) {
    grid-template-columns: repeat(2, 1fr);

    div:last-child {
      grid-column: span 2;
    }
  }
  @media (max-width: 720px) {
    grid-template-columns: 1fr;

    div:last-child {
      grid-column: 1;
    }
  }
`;
