/* eslint-disable @typescript-eslint/no-explicit-any */

// Lib
import { FC, useEffect, useState } from "react";
import { Select } from "antd";
// Api
import { useGetUsersQuery } from "rtkQuery/query/usersAPI";
// Hooks
import { useNotification } from "hooks";
// Types
import { FilterField, FilterStateItem } from "types/tableFilters";
// Theme
import { theme } from "theme";
// Constants
// Helpers
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { ArrowDropDownBlackIcon, SearchBlackIcon } from "icons";
// Components
import { Avatar } from "components";
import { Input } from "components/Form";
// Styled
import { FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { CloseIcon, FilterSelect } from "../styled";

const { Option } = Select;

interface usersFilterProps {
  name: string;
  isOpen: boolean;
  field: FilterField;
  selected: FilterStateItem[] | [];
  value: string;
  setIsOpen: (arg: boolean) => void;
  setFilterValue: (value: string, name: string) => void;
}

export const UsersFilter: FC<usersFilterProps> = ({
  name,
  isOpen,
  field,
  value,
  setIsOpen,
  setFilterValue,
}) => {
  const { openNotification } = useNotification();

  const [searchValue, setSearchValue] = useState<string>("");

  const { data: usersData, error } = useGetUsersQuery({
    query: {},
  });

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  const getUserValue = (
    value: string,
    options: { label: string; value: string }[],
  ) => {
    if (!value) {
      return `User: `;
    }
    const option = options?.find(el => el.value === value);

    if (option) {
      return `User: ${option?.label}`;
    }

    return `User: ${value}`;
  };

  const usersOptions =
    usersData?.map(({ fullName, email, id }) => {
      return {
        label: fullName,
        value: id,
        searchData: {
          fullName,
          email,
        },
      };
    }) || null;

  const filteredOptions = !searchValue
    ? usersOptions
    : usersOptions.filter(user => {
        const { fullName, email } = user.searchData;

        const isNameMatch = fullName
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase());

        const isEmailMatch = email
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase());

        if (isNameMatch || isEmailMatch) {
          return true;
        }
        return false;
      });

  return (
    <FilterSelect
      $inputType={field}
      suffixIcon={
        <FlexContainer $align="center">
          <ArrowDropDownBlackIcon />
          <div
            style={{
              width: 20,
              height: 30,
              borderLeft: `1px solid ${theme.color.borderMedium}`,
            }}
          />
        </FlexContainer>
      }
      removeIcon={<CloseIcon />}
      open={isOpen}
      popupMatchSelectWidth={300}
      onMouseDown={() => setIsOpen(true)}
      onDropdownVisibleChange={visible => setIsOpen(visible)}
      onChange={v => setFilterValue(v, name)}
      value={getUserValue(value, usersOptions)}
      dropdownRender={menu => (
        <FlexContainer $fullwidth $column $gap={8} $padding="12px">
          <Input
            prefix={<SearchBlackIcon />}
            placeholder="Search user"
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchValue(e.target.value)
            }
          />
          {menu}
        </FlexContainer>
      )}
    >
      {filteredOptions?.map(o => (
        <Option key={o.value} value={o.value}>
          <FlexContainer $align="center" $gap={8}>
            <FlexContainer $width="28px">
              <Avatar name={o.searchData.fullName} />
            </FlexContainer>

            <Typography.TitleThin
              style={{
                padding: "8px 0px",
                width: 220,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {o.label}
            </Typography.TitleThin>
          </FlexContainer>
        </Option>
      ))}
    </FilterSelect>
  );
};
