import { styled } from "styled-components";

export const StaticsGrid = styled.div`
  display: grid;
  gap: 16px;
  margin-bottom: 16px;

  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 715px) {
    grid-template-columns: 1fr;
  }
`;
