// Lib
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Api
import { useGetModifiersQuery } from "rtkQuery/query/modifiersAPI";
// Hooks
import { useNotification, usePermissions, useTable, useViewport } from "hooks";
import { useAppSelector } from "hooks/redux";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Types
import { ETable } from "types/tableFilters";
import { TableAction } from "types/common";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { MenuIcon, PencilIcon } from "icons";
// Components
import { Pagination, Table } from "components";
// Styled
import { FlexContainer, PageButtonsContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { PageWrapper, SelectContainer } from "./styled";

import { columns, items } from "./config";
import { Select } from "components/Form";
import { useGetLocationsQuery } from "rtkQuery/query/locationsAPI";

export const Modifiers: FC = () => {
  const { isDesktop } = useViewport();

  const navigate = useNavigate();
  const location = useLocation();

  const companyId = useAppSelector(getUserCompanyId);

  const { data: locationsData, isLoading: isLocationsLoading } =
    useGetLocationsQuery({ companyId });

  const { canReadProduct } = usePermissions();

  const { openNotification } = useNotification();

  const [tab, setTab] = useState("");

  const [locationIdValue, setLocationIdValue] = useState<string>("");

  const {
    orderDirection,
    orderBy,
    currentPage,
    pageSize,
    search,
    debouncedSearch,
    setCurrentPage,
    setPageSize,
    setSearch,
    handleSort,
  } = useTable({ name: ETable.Modifiers });

  const {
    data: tableData,
    isFetching: isTableDataFetching,
    error,
  } = useGetModifiersQuery(
    {
      companyId,
      siteId: locationIdValue,
      query: {
        page: currentPage,
        size: pageSize,
        orderBy: orderBy,
        orderDirection: orderDirection,
        query: debouncedSearch,
        modifierType: tab,
      },
    },
    { refetchOnMountOrArgChange: true, skip: !companyId || !locationIdValue },
  );

  useEffect(() => {
    if (error) {
      errorHandler({ error, openNotification });
    }
  }, [error]);

  useEffect(() => {
    if (!companyId) {
      return;
    }

    if (location?.state?.locationIdValue) {
      setLocationIdValue(location?.state?.locationIdValue);
    }

    if (location?.state?.tab) {
      setTab(location?.state?.tab);
    }
  }, []);

  const handleSetTab = (value: string) => {
    setCurrentPage(1);
    setTab(value);
  };

  const actions: TableAction[] = [
    {
      title: "",
      Icon: PencilIcon,
      disabled: !canReadProduct,
      onClick: ({ id }) =>
        navigate(`/loop/addons-modifiers/edit/${id}`, {
          state: { tab, locationIdValue },
        }),
    },
  ];

  const locations =
    locationsData?.length &&
    locationsData.map(el => ({ label: el.name, value: el.id }));

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    handleSort(sorter?.field, sorter?.order);
  };

  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer
          $fullwidth
          $column
          $gap={20}
          $grow={1}
          $padding={isDesktop && "0 0 32px"}
        >
          <FlexContainer $align="center" $justify="space-between">
            <Typography.H1>Modifiers</Typography.H1>
            <PageButtonsContainer>
              <SelectContainer>
                <Select
                  label="Location"
                  loading={isLocationsLoading}
                  value={locationIdValue}
                  options={locations}
                  onChange={value => setLocationIdValue(value)}
                />
              </SelectContainer>
            </PageButtonsContainer>
          </FlexContainer>

          <Table
            isLoading={isTableDataFetching}
            rowKey={record => record.id}
            header={{
              tabs: { tab, setTab: handleSetTab, items },
              search: { value: search, setValue: setSearch },
            }}
            empty={{
              icon: MenuIcon,
              title: !locationIdValue
                ? "No location selected"
                : isTableDataFetching
                ? "Loading"
                : "No products found",
              description: !locationIdValue
                ? "Please select location."
                : isTableDataFetching
                ? ""
                : "Please add products",
            }}
            accentColumn={2}
            accentColumnWidth="48%"
            columns={columns}
            dataSource={tableData?.data?.length && tableData.data}
            actions={actions}
            onChange={handleTableChange}
          />
        </FlexContainer>

        <Pagination
          onPage
          padding="12px 16px"
          pageSize={pageSize}
          currentPage={currentPage}
          showSizeChanger={!isDesktop}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          totalItems={tableData?.total}
        />
      </PageWrapper>
    </>
  );
};
