// Lib
import { FC, useEffect, useState } from "react";
import { Upload, UploadFile } from "antd";
// Api
import { useUploadMediaDataMutation } from "rtkQuery/query/uploadMediaAPI";
// Hooks
import { useNotification } from "hooks";
// Types
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import { RcFile } from "antd/es/upload";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import { FileUploadIcon } from "icons";
// Components
import { Tooltip } from "components/Tooltip";
// Styled
import { Wrapper, StyledUpload, UploadButton } from "./styled";
import { ErrorMessage } from "../styled";

const BYTES_IN_KBYTE = 1024;
const ALLOWED_FILE_TYPES = ["image/png"];
const MAX_FILE_SIZE_KB = 10;
const IMAGE_MAX_WIDTH = 100;
const IMAGE_MAX_HEIGHT = 100;

interface TagUploadImageProps {
  errorMessage: string;
  open: boolean;
  disabled?: boolean;
  value?: string | null;
  onChange?: (args: string) => void;
  setErrorMessage: (value: string) => void;
}

export const TagUploadImage: FC<TagUploadImageProps> = ({
  errorMessage,
  disabled,
  value,
  setErrorMessage,
  onChange,
}) => {
  const { openNotification } = useNotification();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [saveImage, { isLoading }] = useUploadMediaDataMutation();

  useEffect(() => {
    if (!value) {
      setFileList([]);
      setErrorMessage("");
    } else {
      setFileList([
        {
          uid: value,
          name: value,
          status: "done",
          url: value,
          thumbUrl: value,
        },
      ]);
    }
    setErrorMessage("");
  }, [value]);

  const checkImageDimension = async (file: RcFile): Promise<boolean> => {
    try {
      const imageUrl = URL.createObjectURL(file);
      const image = new Image();
      image.src = imageUrl;

      await new Promise((resolve, reject) => {
        image.onload = resolve;
        image.onerror = reject;
      });

      URL.revokeObjectURL(imageUrl);

      return image.width <= IMAGE_MAX_WIDTH && image.height <= IMAGE_MAX_HEIGHT;
    } catch (error) {
      console.error("Failed to check image dimensions:", error);
      return false;
    }
  };

  const beforeUploadValidation = async (file: RcFile) => {
    const isFileTypeAllowed = ALLOWED_FILE_TYPES.includes(file.type);

    if (!isFileTypeAllowed) {
      setErrorMessage("You can only upload PNG file");
      return false;
    }

    const isFileSizeAllowed = file.size < MAX_FILE_SIZE_KB * BYTES_IN_KBYTE;

    if (!isFileSizeAllowed) {
      setErrorMessage(`Image must be smaller than ${MAX_FILE_SIZE_KB}KB`);
      return false;
    }

    setErrorMessage("");

    const isValidDimensions = await checkImageDimension(file);

    if (!isValidDimensions) {
      setErrorMessage(
        `Image max height and width must be  ${IMAGE_MAX_WIDTH}x${IMAGE_MAX_HEIGHT}px`,
      );
      return false;
    }

    return (
      (isFileTypeAllowed && isFileSizeAllowed && isValidDimensions) ||
      Upload.LIST_IGNORE
    );
  };

  const customUploadFile = async ({
    file,
    onSuccess,
    onError,
  }: RcCustomRequestOptions) => {
    const data = new FormData();
    data.append("file", file);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await saveImage({ data: formData }).unwrap();

      onChange(response.url);
      onSuccess(response);
    } catch (error) {
      onError({
        status: 500,
        name: "",
        message: "Image uploading error",
      });
      errorHandler({ error, openNotification });
    }
  };

  const handleFileRemove = () => {
    setFileList([]);
    onChange && onChange(null);
    setErrorMessage("");
  };

  return (
    <Wrapper $errorMessage={!!errorMessage}>
      <StyledUpload
        accept="image/png"
        $errorMessage={!!errorMessage}
        listType="picture"
        maxCount={1}
        customRequest={customUploadFile}
        beforeUpload={beforeUploadValidation}
        fileList={fileList}
        onRemove={handleFileRemove}
        disabled={disabled}
      >
        <Tooltip
          title={
            <>
              <p>{`allowed extension: *.png`}</p>
              <p>{`max file size: ${MAX_FILE_SIZE_KB}KB `}</p>
              <p>{`max dimensions: ${IMAGE_MAX_WIDTH}x${IMAGE_MAX_HEIGHT}px `}</p>
            </>
          }
        >
          <UploadButton
            icon={<FileUploadIcon />}
            loading={isLoading}
            disabled={disabled}
          >
            Upload image
          </UploadButton>
        </Tooltip>
      </StyledUpload>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};
