// Lib
import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// Api
import {
  useDeleteMenusMutation,
  useGetMenusQuery,
} from "rtkQuery/query/menusAPI";
// Hooks
import { useAppSelector } from "hooks/redux";
import { useNotification, usePermissions, useTable, useViewport } from "hooks";
// Selectors
import { getUserCompanyId } from "rtkQuery/selectors";
// Constants
import { ADMIN_ROUTES, NOTIFICATIONS } from "consts";
// Types
import { ETable } from "types/tableFilters";
import { MenuTableAction } from "./types";
// Theme
import { theme } from "theme";
// Helpers
import { generateUniqueId } from "helpers/dataHelpers";
// Utils
import { errorHandler } from "utils/errorHandler";
// Icons
import {
  EyeVisibleIcon,
  PencilIcon,
  PlusIcon,
  RefreshIcon,
  RightBurgerMenuIcon,
  TrashIcon,
} from "icons";
// Components
import { ConfirmDialog, DropDown, Pagination, Table } from "components";
// Styled
import { FlexContainer, PageButtonsContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import { PageWrapper } from "./styled";

import { columns } from "./config";

export const Menus: FC = () => {
  const { isDesktop } = useViewport();
  const navigate = useNavigate();

  const companyId = useAppSelector(getUserCompanyId);

  const { openNotification } = useNotification();

  const { canCreateMenu, canReadMenu, canUpdateMenu, canDeleteMenu } =
    usePermissions();

  const {
    orderDirection,
    orderBy,
    currentPage,
    pageSize,
    search,
    debouncedSearch,
    setCurrentPage,
    setPageSize,
    setSearch,
    handleSort,
  } = useTable({ name: ETable.Menus });

  const [showDeleteMenusModal, setShowDeleteMenusModal] =
    useState<boolean>(false);

  const deleteItemData = useRef<{ id: string; name: string } | null>(null);

  const [deleteMenu, { isLoading: isDeleteLoading }] = useDeleteMenusMutation();

  const {
    data: tableData,
    isFetching: isTableDataFetching,
    isError,
    error,
    refetch,
  } = useGetMenusQuery(
    {
      companyId,
      query: {
        page: currentPage,
        size: pageSize,
        orderBy: orderBy,
        orderDirection: orderDirection,
        name: debouncedSearch,
      },
    },
    { refetchOnMountOrArgChange: true, skip: !companyId },
  );

  useEffect(() => {
    if (isError) {
      errorHandler({ error, openNotification });
    }
  }, [isError]);

  const handleCreateMenu = () => {
    navigate(ADMIN_ROUTES.ADD_MENUS.path);
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    handleSort(sorter?.field, sorter?.order);
  };

  const handleDeleteItem = (id: string, name: string) => {
    deleteItemData.current = { id, name };
    setShowDeleteMenusModal(true);
  };

  const onConfirmDelete = async () => {
    try {
      await deleteMenu({
        data: { ids: [deleteItemData.current.id] },
      }).unwrap();

      openNotification({
        message: `${deleteItemData.current.name} deleted successfully`,
      });

      deleteItemData.current = null;

      setShowDeleteMenusModal(false);
    } catch (error) {
      errorHandler({ error, openNotification });
    }
  };

  const handleCloseModal = () => {
    deleteItemData.current = null;
    setShowDeleteMenusModal(false);
  };

  const actions: MenuTableAction[] = [
    {
      title: "Edit",
      Icon: PencilIcon,
      disabled: !canUpdateMenu,
      onClick: ({ id }) => navigate(`/loop/menus/edit/${id}`),
    },
    {
      title: "Preview",
      Icon: EyeVisibleIcon,
      disabled: !canReadMenu,
      onClick: ({ id }) => navigate(`/loop/menus/preview/${id}`),
    },
    {
      title: "Delete",
      Icon: TrashIcon,
      disabled: !canDeleteMenu,
      onClick: ({ id, name }) => handleDeleteItem(id, name),
    },
  ];

  const dropDownMenuItems = [
    {
      key: generateUniqueId(),
      label: "Refresh",
      onClick: refetch,
    },
    {
      key: generateUniqueId(),
      label: "Create",
      onClick: handleCreateMenu,
    },
  ];

  return (
    <>
      <PageWrapper $fullwidth $column>
        <FlexContainer
          $fullwidth
          $column
          $gap={20}
          $grow={1}
          $padding={isDesktop && "0 0 32px"}
        >
          <FlexContainer $align="center" $justify="space-between">
            <Typography.H1>Menus</Typography.H1>

            <PageButtonsContainer>
              {isDesktop ? (
                <>
                  <Button.Heading
                    icon={<RefreshIcon fill={theme.color.text.tertiary} />}
                    onClick={refetch}
                  >
                    Refresh
                  </Button.Heading>

                  {canCreateMenu && (
                    <Button.Heading
                      type="primary"
                      icon={<PlusIcon fill={theme.color.white} />}
                      onClick={handleCreateMenu}
                    >
                      Create
                    </Button.Heading>
                  )}
                </>
              ) : (
                <>
                  <DropDown trigger={["click"]} items={dropDownMenuItems}>
                    <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
                  </DropDown>
                </>
              )}
            </PageButtonsContainer>
          </FlexContainer>

          <Table
            isLoading={isTableDataFetching}
            header={{
              totalCount: tableData?.total,
              search: { value: search, setValue: setSearch },
            }}
            accentColumn={1}
            accentColumnWidth="60%"
            columns={columns}
            dataSource={tableData?.data?.length && tableData.data}
            actions={actions}
            onChange={handleTableChange}
          />
        </FlexContainer>

        <Pagination
          onPage
          padding="12px 16px"
          pageSize={pageSize}
          currentPage={currentPage}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          totalItems={tableData?.total}
        />
      </PageWrapper>

      <ConfirmDialog
        open={showDeleteMenusModal}
        Icon={TrashIcon}
        message={`${deleteItemData.current?.name} will be deleted`}
        description={NOTIFICATIONS.APROVE_ACTION_MESSAGE}
        firstCTAButton={{
          title: "Delete Menu",
          status: "danger",
          loading: isDeleteLoading,
          onClick: () => onConfirmDelete(),
        }}
        onCancel={handleCloseModal}
      />
    </>
  );
};
