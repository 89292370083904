import dayjs from "dayjs";
import { OrderStatus } from "./orders";

export enum AnalyticsPeriodFilterValues {
  Custom = "custom",
  Today = "today",
  Yesterday = "1D",
  ThisWeek = "1W",
  LastSevenDays = "7D",
  ThisMonth = "1M",
  LastFourWeeks = "4W",
  CurrentQuarter = "3M",
  CurrentYear = "1Y",
}

export const AnalyticsPeriodFilterLabels = {
  custom: "Custom",
  today: "Today",
  "1D": "Yesterday",
  "1W": "This week",
  "7D": "Last 7 days",
  "1M": "This month",
  "4W": "Last 4 weeks",
  "3M": "Current quarter",
  "1Y": "Current year",
};

export enum AnalyticsPeriodGroupByValues {
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
}

export enum AnalyticsPeriodGroupByLabels {
  hour = "Hour",
  day = "Day",
  week = "Week",
  month = "Month",
}

export enum ChartCompareValues {
  PreviousPeriod = "period",
  SelectedLocation = "location",
}

export enum ChartCompareLabels {
  period = "Previous period",
  location = "Selected location",
}

export type ChartLinesConfig = {
  dataKey: string;
  stroke: string;
  activeDot: { r: number; stroke?: string; fill?: string };
  strokeDasharray?: string;
};

export type ChartLegendConfig = {
  title: string;
  color: string;
  textColor?: string;
};

export type MergedChartSlotData = {
  name: string | number;
  [AnalyticsStatsSummaryKey.ItemSold]?: Record<string, number | null>;
  [AnalyticsStatsSummaryKey.Revenue]?: Record<string, number | null>;
  [AnalyticsStatsSummaryKey.AverageOrderValue]?: Record<string, number | null>;
  [AnalyticsStatsSummaryKey.OrderIssueOvertime]?: Record<string, number | null>;
  [AnalyticsStatsSummaryKey.TotalOrders]?: Record<string, number | null>;
  [AnalyticsStatsSummaryKey.TotalRevenue]?: Record<string, number | null>;
  slotStartTime: dayjs.Dayjs;
  slotEndTime: dayjs.Dayjs;
  prevSlotStartTime?: dayjs.Dayjs;
  prevSlotEndTime?: dayjs.Dayjs;
  groupBy: AnalyticsPeriodGroupByValues;
};

export type ChartLinesData = {
  slotsData: MergedChartSlotData[];
  linesConfig: ChartLinesConfig[];
  legendConfig: ChartLegendConfig[];
};

export type ChartDataCoordinates = Record<string, string | number | object>;

export type ChartData = {
  name: string | number;
  groupBy: AnalyticsPeriodGroupByValues;
  tooltipTitles?: Record<string, string | number>;
} & ChartDataCoordinates;

export type ChartSlotData = {
  name: string | number;
  slotStartTime: dayjs.Dayjs;
  slotEndTime: dayjs.Dayjs;
  data: {
    [AnalyticsStatsSummaryKey.ItemSold]?: number;
    [AnalyticsStatsSummaryKey.Revenue]?: number;
    [AnalyticsStatsSummaryKey.AverageOrderValue]?: number;
    [AnalyticsStatsSummaryKey.OrderIssueOvertime]?: number;
    [AnalyticsStatsSummaryKey.TotalOrders]?: number;
  };
  groupBy: AnalyticsPeriodGroupByValues;
};

export type AnalyticsDateFilter = {
  current: {
    startTime: string;
    endTime: string;
  };
  prev: {
    startTime: string;
    endTime: string;
  };
  groupBy: AnalyticsPeriodGroupByValues;
  timezoneOffset: number;
};

export type AnalyticsStateDateFilterValue = {
  value: AnalyticsPeriodFilterValues;
  //use for AnalyticsPeriodFilterValues.Custom
  customTimePeriod?: {
    currentStartTime: string;
    currentEndTime: string;
    prevStartTime: string;
    prevEndTime: string;
    groupBy: AnalyticsPeriodGroupByValues;
  };
};

export interface AnalyticsState {
  location: string;
  orderStatus: OrderStatus | "";
  dateFilter: AnalyticsStateDateFilterValue;
  comparing: ChartCompareValues;
}

export enum AnalyticsChartKey {
  OrdersChart = "ordersChart",
  MenuItemsChart = "menuItemsChart",
  LocationsChart = "locationsChart",
}

export enum AnalyticsStatsSummaryKey {
  ItemSold = "itemSold",
  Revenue = "revenue",
  TotalRevenue = "totalRevenue",
  OrderIssueOvertime = "orderIssueOvertime",
  AverageOrderValue = "averageOrderValue",
  TotalOrders = "totalOrders",
  Orders = "orders",
}

export enum AnalyticsStatsSummaryLabels {
  itemSold = "Item Sold",
  revenue = "Revenue",
  averageOrderValue = "Average order value",
  totalOrders = "Total Order",
  orderIssueOvertime = "Order issue overtime",
  orders = "Orders",
}

export type AnalyticsStatsSummaryValues = {
  dataKey: AnalyticsStatsSummaryKey;
  value: number;
  prevValue: number;
};

export type OrdersTableCalendarRow = {
  time: string;
  mon: number;
  tue: number;
  wed: number;
  thu: number;
  fri: number;
  sat: number;
  sun: number;
};

export interface GetOrdersSummaryAnalyticsRequest {
  companyId: string;
  query: {
    siteId?: string;
    deliveredOnly?: boolean;
    dateBefore: string;
    dateAfter: string;
    dateBeforePrev: string;
    dateAfterPrev: string;
    timezoneOffset: number;
  };
}

export interface GetOrdersSummaryAnalyticsResponse {
  current: {
    totalOrders: number;
    totalFailedOrders: number;
    totalRevenue: string;
    averageOrderValue: string;
  };
  prev: {
    totalOrders: number;
    totalFailedOrders: number;
    totalRevenue: string;
    averageOrderValue: string;
  };
}

export interface GetOrderRevenueBreakdownAnalyticsRequest {
  companyId: string;
  query: {
    siteId?: string;
    period: "day" | "month";
    dateBefore: string;
    dateAfter: string;
    timezoneOffset: number;
  };
}

export interface GetOrderRevenueBreakdownAnalyticsResponse {
  items: {
    period: string;
    siteId: string | null;
    totalDeliveryCost: string;
    totalDiscount: string;
    totalRevenue: string;
  }[];
}

export interface GetOrderHourlyDistributionAnalyticsRequest {
  companyId: string;
  query: {
    dateBefore: string;
    dateAfter: string;
    timezoneOffset: number;
  };
}

export interface GetOrderHourlyDistributionAnalyticsResponse {
  items: OrdersTableCalendarRow[];
}

export interface GetOrdersChartAnalyticsRequest {
  companyId: string;
  query: {
    siteId?: string;
    deliveredOnly?: boolean;
    dateBefore: string;
    dateAfter: string;
    dateBeforePrev: string;
    dateAfterPrev: string;
    compare: ChartCompareValues;
    period: AnalyticsPeriodFilterValues;
    timezoneOffset: number;
    groupBy: AnalyticsPeriodGroupByValues;
  };
}
export interface GetOrdersChartAnalyticsResponse {
  data: { slotsData: MergedChartSlotData[] };
}

export interface GetMenuItemsSummaryAnalyticsRequest {
  companyId: string;
  query: {
    siteId?: string;
    dateBefore: string;
    dateAfter: string;
    dateBeforePrev: string;
    dateAfterPrev: string;
    timezoneOffset: number;
  };
}

export interface GetMenuItemsSummaryAnalyticsResponse {
  totalCanceledOrders: number;
  totalSnoozedCount: number;
  totalSnoozedTime: string;
}

export interface GetMenuItemsListAnalyticsRequest {
  companyId: string;
  query: {
    siteId?: string;
    dateBefore: string;
    dateAfter: string;
    dateBeforePrev: string;
    dateAfterPrev: string;
    timezoneOffset: number;
    name?: string;
    page: number;
    size: number;
    orderBy?: string;
    orderDirection?: string;
  };
}

export interface MenuItemListItem {
  menuItemId: string;
  menuItemName: string;
  revenueBase: string;
  individualSold: number;
  totalSold: number;
}

export interface GetMenuItemsListAnalyticsResponse {
  data: MenuItemListItem[];
  page: number;
  size: number;
  total: number;
  totalPages: number;
}

export interface GetMenuItemDetailsSummaryAnalyticsRequest {
  companyId: string;
  itemId: string;
  query: {
    siteId?: string;
    dateBefore: string;
    dateAfter: string;
    dateBeforePrev: string;
    dateAfterPrev: string;
    timezoneOffset: number;
  };
}

export interface GetMenuItemDetailsSummaryAnalyticsResponse {
  totalSold: number;
  totalRevenue: string;
  popularDay: string;
  popularTime: string;
  current: {
    totalSold: number;
    totalRevenue: string;
  };
  prev: {
    totalSold: number;
    totalRevenue: string;
  };
}

export interface GetMenuItemDetailsChartAnalyticsRequest {
  companyId: string;
  itemId: string;
  query: {
    siteId?: string;
    deliveredOnly?: boolean;
    dateBefore: string;
    dateAfter: string;
    dateBeforePrev: string;
    dateAfterPrev: string;
    compare: ChartCompareValues;
    period: AnalyticsPeriodFilterValues;
    timezoneOffset: number;
    groupBy: AnalyticsPeriodGroupByValues;
  };
}

export interface GetMenuItemDetailsChartAnalyticsResponse {
  data: { slotsData: MergedChartSlotData[] };
}
