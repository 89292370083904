// Lib
import { FC } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Space } from "antd";
// Hooks
import { usePermissions, useViewport } from "hooks";
// Types
import {
  LocationAddressForm,
  LocationAddressFormSubmitData,
} from "types/locations";
// Constants
import { ADMIN_ROUTES } from "consts";
// Icons
import { RightBurgerMenuIcon } from "icons";
// Components
import { DropDown } from "components";
import { Input, NumericInput, Switch, Select } from "components/Form";
// Styled
import { ContentBox, FlexContainer, InputsGridContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";
import { DropDownContainer, SubmitButton, TitleContainer } from "./styled";

import { resolver } from "./validation";
import { defaultValues } from "./defaultValues";

import { getGeoCoordinates } from "../../../../../../utils/getGeoCoordinates";

interface EditLocationForm {
  addressData?: LocationAddressForm;
  handleSendRequest?: (args: LocationAddressFormSubmitData) => void;
}

export const EditLocationForm: FC<EditLocationForm> = ({
  addressData,
  handleSendRequest,
}) => {
  const { isDesktop } = useViewport();
  const navigate = useNavigate();

  const id = useParams();
  const isEdit = !!id;
  const { canCreateSite, canUpdateSite } = usePermissions();

  if ((isEdit && !canUpdateSite) || (!isEdit && !canCreateSite)) {
    navigate(ADMIN_ROUTES.LOCATIONS.path);
  }

  const { handleSubmit, control, setValue, watch } =
    useForm<LocationAddressForm>({
      defaultValues: addressData ? addressData : defaultValues,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolver,
    });

  const name = watch("name");
  const country = watch("country");
  const street = watch("street");
  const number = watch("number");
  const city = watch("city");
  const postcode = watch("postcode");
  const simphonyNodeId = watch("simphonyNodeId");

  const onSubmit: SubmitHandler<LocationAddressForm> = async data => {
    const coorditates = await getGeoCoordinates(data);
    handleSendRequest({
      ...data,
      lat: coorditates?.lat || "0",
      lng: coorditates?.lng || "0",
    });
  };

  const disabled = isEdit && !canUpdateSite;

  const isButtonDisabled =
    !name ||
    !country ||
    !street ||
    !number ||
    !city ||
    !postcode ||
    !simphonyNodeId;

  const dropDownMenuItems = [
    {
      key: "3",
      label: "Save location",
      disabled:
        (isEdit && !canUpdateSite) ||
        (!isEdit && !canCreateSite) ||
        isButtonDisabled,
      onClick: () => handleSubmit(onSubmit)(),
    },
  ];

  return (
    <>
      <FlexContainer
        as="form"
        $column
        $gap={16}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ContentBox $column>
          <TitleContainer>
            <Typography.H2>Location Address</Typography.H2>

            <Controller
              name={"isHomeSite"}
              control={control}
              render={({ field }) => (
                <Switch
                  label="Set as main location"
                  labelPosition="left"
                  {...field}
                  checked={field.value}
                  disabled={disabled}
                />
              )}
            />
          </TitleContainer>

          <InputsGridContainer>
            <Controller
              name={"name"}
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="Location name/restaurant/business"
                  required
                  {...field}
                  fieldState={fieldState}
                  disabled={disabled}
                />
              )}
            />

            <Controller
              name={"country"}
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  required
                  label="Country"
                  {...field}
                  fieldState={fieldState}
                  disabled={disabled}
                  options={[{ label: "Kuwait", value: "Kuwait" }]}
                />
              )}
            />

            <Controller
              name={"street"}
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="Street"
                  required
                  {...field}
                  disabled={disabled}
                  fieldState={fieldState}
                />
              )}
            />

            <Controller
              name={"number"}
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="Number"
                  required
                  {...field}
                  disabled={disabled}
                  fieldState={fieldState}
                />
              )}
            />

            <Controller
              name={"city"}
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="City"
                  required
                  {...field}
                  disabled={disabled}
                  fieldState={fieldState}
                />
              )}
            />

            <Controller
              name={"postcode"}
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="Postal code"
                  required
                  {...field}
                  disabled={disabled}
                  fieldState={fieldState}
                />
              )}
            />
          </InputsGridContainer>
        </ContentBox>

        <ContentBox $column>
          <TitleContainer>
            <Typography.H2>Contact Informations</Typography.H2>
          </TitleContainer>

          <InputsGridContainer>
            <div style={{ width: "100%" }}>
              <Typography.Label>Phone number</Typography.Label>

              <Space.Compact style={{ width: "100%" }}>
                <Controller
                  name={"phoneCode"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <Select
                      phone
                      {...field}
                      fieldState={fieldState}
                      disabled={disabled}
                      options={[{ label: "+965", value: "+965" }]}
                    />
                  )}
                />

                <Controller
                  name={"phoneNumber"}
                  control={control}
                  render={({ field }) => (
                    <NumericInput
                      phone
                      {...field}
                      disabled={disabled}
                      fieldChange={str => setValue("phoneNumber", str)}
                    />
                  )}
                />
              </Space.Compact>
            </div>

            <Controller
              name={"email"}
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="E-mail"
                  {...field}
                  disabled={disabled}
                  fieldState={fieldState}
                />
              )}
            />
          </InputsGridContainer>
        </ContentBox>

        <ContentBox $column>
          <TitleContainer>
            <Typography.H2>Basic Settings</Typography.H2>
          </TitleContainer>

          <InputsGridContainer>
            <Controller
              name={"simphonyNodeId"}
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  label="Simphony NODE ID"
                  required
                  {...field}
                  disabled={disabled}
                  fieldState={fieldState}
                />
              )}
            />
          </InputsGridContainer>
        </ContentBox>

        {isDesktop ? (
          <SubmitButton
            type="primary"
            htmlType="submit"
            disabled={
              (isEdit && !canUpdateSite) ||
              (!isEdit && !canCreateSite) ||
              isButtonDisabled
            }
          >
            Save location
          </SubmitButton>
        ) : (
          <DropDownContainer>
            <DropDown trigger={["click"]} items={dropDownMenuItems}>
              <Button.SquaredIcon icon={<RightBurgerMenuIcon />} />
            </DropDown>
          </DropDownContainer>
        )}
      </FlexContainer>
    </>
  );
};
