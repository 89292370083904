// Lib
import { FC, memo } from "react";
// Types
import { TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableAction } from "types/common";
import { EmptyMessage, TableHeaderProps } from "./types";
// Helpers
import { createTableColumns } from "./helpers";
// Components
import { Loader } from "components/Loader";
import { TableHeader } from "./components";
// Styled
import { AntTableStyled, TableShadowContainer } from "./styled";
import { NoItemsContent } from "components/NoItemsContent";
import { FlexContainer } from "styled/Box";

interface ITableProps {
  isLoading?: boolean;
  wrap?: boolean;
  shadow?: boolean;
  accentColumn?: number;
  accentColumnWidth?: string;
  actions?: TableAction[];
  header?: TableHeaderProps;
  withPagination?: boolean;
  ordersCalendarTable?: boolean;
  empty?: EmptyMessage;
  grayRowsIds?: string[];
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const Table: FC<ITableProps & TableProps<any>> = memo(
  ({
    isLoading = false,
    shadow = true,
    wrap = false,
    ordersCalendarTable,
    withPagination,
    accentColumn,
    accentColumnWidth,
    header,
    columns,
    actions,
    empty,
    rowKey = "id",
    grayRowsIds,
    onRow,
    ...props
  }) => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const columnsData: ColumnsType<any> = createTableColumns({
      columns,
      actions,
    });

    const locale = empty && {
      emptyText: (
        <FlexContainer $align="center" $justify="center" $padding="32px 0">
          <NoItemsContent
            Icon={empty.icon}
            message={empty.title}
            description={empty.description}
          />
        </FlexContainer>
      ),
    };

    return (
      <TableShadowContainer $withPagination={withPagination} $shadow={shadow}>
        {header && (
          <TableHeader
            isLoading={isLoading}
            totalCount={header?.totalCount}
            tabs={header.tabs}
            search={header?.search}
            leftTitle={header?.leftTitle}
            suffix={header?.suffix}
            filter={header?.filter}
            ordersCalendarTable={ordersCalendarTable}
          />
        )}

        {/* Unable to use Typescript, Styled Components, and Ant Design (table) together
        GitHub issue https://github.com/styled-components/styled-components/issues/3752 */}
        {/*  eslint-disable-next-line  @typescript-eslint/no-explicit-any */}
        <AntTableStyled<(props: TableProps<any>) => JSX.Element>
          rowKey={rowKey}
          $clicable={!!onRow}
          $wrap={wrap}
          $grayRowsIds={grayRowsIds}
          $accentColumn={accentColumn}
          $accentColumnWidth={accentColumnWidth}
          $withPagination={withPagination}
          $ordersCalendarTable={ordersCalendarTable}
          columns={columnsData}
          pagination={false}
          locale={locale}
          onRow={onRow}
          loading={{
            spinning: isLoading,
            indicator: (
              <>
                <Loader />
              </>
            ),
          }}
          {...props}
        />
      </TableShadowContainer>
    );
  },
);

Table.displayName = "Table";
