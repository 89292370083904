// Lib
import { FC, forwardRef } from "react";
import { SelectProps as AntDSelectProps, RefSelectProps } from "antd";
import { ControllerFieldState } from "react-hook-form";
// Styled
import { Typography } from "styled/Typography";
import { SelectField, ErrorMessage } from "../styled";
import { Tag } from "components/Tag";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FlexContainer } from "styled/Box";
import { Checkbox } from "../Checkbox";
import { Button } from "styled/Buttons";
import { DefaultOptionType } from "antd/es/select";

interface SelectProps extends AntDSelectProps {
  phone?: boolean;
  required?: boolean;
  selectAll?: boolean;
  label?: string;
  fieldState?: ControllerFieldState;
  fieldError?: boolean | string;
}

export const TagsSelect: FC<SelectProps> = forwardRef<
  RefSelectProps,
  SelectProps
>(
  (
    {
      required,
      phone,
      label,
      fieldError,
      fieldState,
      mode = "tags",
      selectAll = false,
      options,
      value,
      onChange,
      ...props
    },
    ref,
  ) => {
    const error = fieldError || fieldState?.error?.message || false;

    const tagRender = (props: CustomTagProps) => {
      const { value, closable, onClose } = props;

      const selectedOptionLabel = options.find(
        o => o.value === value,
      )?.renderValue;

      const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
      };

      return (
        <Tag
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{ marginRight: 3 }}
          label={selectedOptionLabel || value}
        />
      );
    };

    const createOptions = (options: DefaultOptionType[]) => {
      return options?.map(option => ({
        label: (
          <FlexContainer key={label} $align="center" $justify="space-between">
            <div>{option.label}</div>
            <div>
              <Checkbox checked={value?.includes(option?.value)}></Checkbox>
            </div>
          </FlexContainer>
        ),
        value: option.value,
        some: option.label,
      }));
    };

    const onSelectAll = () => {
      const optionValues = options.map(option => option.value);
      onChange(optionValues, options);
    };

    return (
      <div>
        {!!label && (
          <Typography.Label>
            {label}
            {!!required && "*"}
          </Typography.Label>
        )}
        <SelectField
          allowClear
          value={value}
          ref={ref}
          $phone={phone}
          status={!!error && "error"}
          {...props}
          mode={mode}
          options={createOptions(options)}
          tagRender={tagRender}
          onChange={onChange}
          menuItemSelectedIcon={null}
          dropdownRender={menu => (
            <>
              {menu}
              {selectAll && (
                <FlexContainer $fullwidth $justify="center" $padding="3px">
                  <Button.Notification type="link" onClick={onSelectAll}>
                    Select All
                  </Button.Notification>
                </FlexContainer>
              )}
            </>
          )}
        />
        {typeof error === "string" && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    );
  },
);

TagsSelect.displayName = "TagsSelect";
