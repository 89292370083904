import {
  analyticsChartLocationsColors,
  mainChartLineConfig,
  mainLegendConfig,
  prevChartLineConfig,
  prevLegendConfig,
} from "pages/Loop/Analytics/config";

import {
  generateDates,
  getFormattedDate,
  getRandomColor,
} from "pages/Loop/Analytics/helpers";

import {
  AnalyticsState,
  AnalyticsStatsSummaryKey,
  ChartCompareValues,
  GetOrdersSummaryAnalyticsResponse,
} from "types/analytics";

import {
  ChartLegendConfig,
  ChartLinesConfig,
} from "components/Chart/LineChart/types";

export const createOrderTabs = (data: GetOrdersSummaryAnalyticsResponse) => {
  if (!data) {
    return [];
  }

  return [
    {
      dataKey: AnalyticsStatsSummaryKey.TotalOrders,
      value: Number(data.current.totalOrders),
      prevValue: Number(data.prev.totalOrders),
    },
    {
      dataKey: AnalyticsStatsSummaryKey.Revenue,
      value: Number(data.current.totalRevenue),
      prevValue: Number(data.prev.totalRevenue),
    },
    {
      dataKey: AnalyticsStatsSummaryKey.AverageOrderValue,
      value: Number(data.current.averageOrderValue),
      prevValue: Number(data.prev.averageOrderValue),
    },
    {
      dataKey: AnalyticsStatsSummaryKey.OrderIssueOvertime,
      value: Number(data.current.totalFailedOrders),
      prevValue: Number(data.prev.totalFailedOrders),
    },
  ];
};

export const createChartConfig = ({
  locations,
  activeLocationId,
  analyticsFilters,
}: {
  locations: {
    label: string;
    value: string;
  }[];
  analyticsFilters: AnalyticsState;
  activeLocationId: string;
}): { legendConfig: ChartLegendConfig[]; linesConfig: ChartLinesConfig[] } => {
  if (analyticsFilters.comparing === ChartCompareValues.SelectedLocation) {
    const locationsChartData = locations.reduce((acc, el, i) => {
      const color = analyticsChartLocationsColors[i] || getRandomColor();
      const isActiveLocation = activeLocationId === el.value;

      acc[el.value] = {
        title: el.label || el.value,
        color,
        dataKey: el.value,
        stroke: color,
        activeDot: { r: isActiveLocation ? 6 : 0, stroke: color, fill: "#fff" },
      };

      return acc;
    }, {});

    const legendConfig: ChartLegendConfig[] = locations.map(location => ({
      title: locationsChartData[location.value].title,
      color: locationsChartData[location.value].color,
    }));

    const linesConfig: ChartLinesConfig[] = locations.map(location => ({
      dataKey: locationsChartData[location.value].dataKey,
      stroke: locationsChartData[location.value].stroke,
      activeDot: locationsChartData[location.value].activeDot,
    }));

    return { legendConfig, linesConfig };
  }

  const { current, prev } = generateDates(analyticsFilters.dateFilter);

  const legendConfig = [
    {
      ...mainLegendConfig,
      title: `Current period (${getFormattedDate(
        current.startTime,
      )} - ${getFormattedDate(current.endTime)})`,
    },
    {
      ...prevLegendConfig,
      title: `Previous period (${getFormattedDate(
        prev.startTime,
      )} - ${getFormattedDate(prev.endTime)})`,
    },
  ];

  return {
    legendConfig,
    linesConfig: [mainChartLineConfig, prevChartLineConfig],
  };
};
