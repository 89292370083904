import styled from "styled-components";
import { FlexContainer } from "styled/Box";
import { Button } from "styled/Buttons";

export const PageTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px;
  gap: 16px;

  @media (max-width: 1120px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const PageWrapper = styled(FlexContainer)`
  min-height: 100%;
`;

export const ItemTitleButton = styled(Button.Notification)`
  padding-left: 0;
`;
