import { FC } from "react";

import { SvgIconConstituentValues } from "types/common";

export const DownArrowIcon: FC<SvgIconConstituentValues> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5303 8.46967C18.2374 8.17678 17.7626 8.17678 17.4697 8.46967L12 13.9393L6.53033 8.46967C6.23744 8.17678 5.76256 8.17678 5.46967 8.46967C5.17678 8.76256 5.17678 9.23744 5.46967 9.53033L11.4697 15.5303C11.7626 15.8232 12.2374 15.8232 12.5303 15.5303L18.5303 9.53033C18.8232 9.23744 18.8232 8.76256 18.5303 8.46967Z"
        fill="#212121"
      />
    </g>
  </svg>
);
