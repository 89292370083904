// Lib
import { FC, memo, useMemo } from "react";
// Types
import {
  KitchenNotificationSoundDurationOptions,
  KitchenNotitficationSoundOptions,
} from "types/kitchen";
import { SettingsFormState } from "../../types";
// Helpers
import { getSeparatedTableData } from "../../helpers";
// Icons
import { DescriptionBlackIcon, MinusIcon, PlusIcon } from "icons";
// Components
import { Table } from "components";
import { InputNumber, Select, Switch } from "components/Form";
// Styled
import { ContentBox, FlexContainer } from "styled/Box";
import { Typography } from "styled/Typography";
import { Button } from "styled/Buttons";

import {
  columns,
  notificationSoundOptions,
  SettingsKeys,
  soundDurationOptions,
} from "../../config";

interface SettingsFormProps {
  isLoading: boolean;
  settings: SettingsFormState | null;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  handleSettingsChange: (key: SettingsKeys, value: any) => Promise<void>;
  handleBooleanValueSettingsChange: (
    key: SettingsKeys,
    value: boolean,
  ) => Promise<void>;
  handleChangeInputValueManualy: (
    value: number,
    key: SettingsKeys,
  ) => Promise<void>;
  handleChangeInputValue: (
    isIncrement: boolean,
    key: SettingsKeys,
  ) => Promise<void>;
}

export const SettingsForm: FC<SettingsFormProps> = memo(
  ({
    isLoading,
    settings,
    handleSettingsChange,
    handleBooleanValueSettingsChange,
    handleChangeInputValueManualy,
    handleChangeInputValue,
  }) => {
    const tableColumns = useMemo(
      () =>
        columns.map(column => {
          if (column.key === "name") {
            return {
              ...column,
              render: value => <div style={{ minWidth: 539 }}>{value}</div>,
            };
          }

          if (column.key === "value") {
            return {
              ...column,
              render: (value, record) => {
                switch (record.key) {
                  case SettingsKeys.NotificationSoundDuration:
                    return (
                      <Select
                        value={value}
                        loading={settings[record.key]?.isLoading}
                        options={soundDurationOptions}
                        onChange={(
                          value: KitchenNotificationSoundDurationOptions,
                        ) => handleSettingsChange(record.key, value)}
                      />
                    );

                  case SettingsKeys.NotificationSound:
                    return (
                      <Select
                        value={value}
                        loading={settings[record.key]?.isLoading}
                        options={notificationSoundOptions}
                        onChange={(value: KitchenNotitficationSoundOptions) =>
                          handleSettingsChange(record.key, value)
                        }
                      />
                    );

                  case SettingsKeys.ManualHandleDelivery:
                    return (
                      <Switch
                        labelPosition="left"
                        loading={settings[record.key]?.isLoading}
                        checked={!value}
                        onChange={(checked: boolean) =>
                          handleBooleanValueSettingsChange(record.key, !checked)
                        }
                      />
                    );
                  case SettingsKeys.DefaultPreparationTime:
                    return (
                      <FlexContainer $gap={4}>
                        <Button.Form
                          style={{ width: 40 }}
                          icon={<MinusIcon />}
                          $shadow="none"
                          disabled={settings[record.key]?.isLoading}
                          onClick={() =>
                            handleChangeInputValue(false, record.key)
                          }
                        />
                        <InputNumber
                          quantityCentered
                          disabled={settings[record.key]?.isLoading}
                          min={0}
                          precision={0}
                          style={{ width: 56 }}
                          controls={false}
                          value={value}
                          onChange={(value: number) =>
                            handleChangeInputValueManualy(value, record.key)
                          }
                        />
                        <Button.Form
                          style={{ width: 40 }}
                          icon={<PlusIcon />}
                          $shadow="none"
                          disabled={settings[record.key]?.isLoading}
                          onClick={() =>
                            handleChangeInputValue(true, record.key)
                          }
                        />
                      </FlexContainer>
                    );

                  default:
                    return (
                      <Switch
                        labelPosition="left"
                        loading={settings[record.key]?.isLoading}
                        checked={value}
                        onChange={(checked: boolean) =>
                          handleBooleanValueSettingsChange(record.key, checked)
                        }
                      />
                    );
                }
              },
            };
          }
          return column;
        }),
      [settings],
    );

    const { orders, notifications } = useMemo(
      () => getSeparatedTableData(settings),
      [settings],
    );

    return (
      <>
        <ContentBox $fullwidth $column $margin="0 0 16px 0">
          <Typography.H2>Orders</Typography.H2>

          <Table
            rowKey={"key"}
            isLoading={isLoading}
            shadow={false}
            columns={tableColumns}
            dataSource={orders}
            empty={{
              icon: DescriptionBlackIcon,
              title: "No settings to show.",
              description: "Try changing location",
            }}
          />
        </ContentBox>

        <ContentBox $fullwidth $column $margin="0 0 16px 0">
          <Typography.H2>Notifications</Typography.H2>

          <Table
            rowKey={"key"}
            isLoading={isLoading}
            shadow={false}
            columns={tableColumns}
            dataSource={notifications}
            empty={{
              icon: DescriptionBlackIcon,
              title: "No settings to show.",
              description: "Try changing location",
            }}
          />
        </ContentBox>
      </>
    );
  },
);

SettingsForm.displayName = "SettingsForm";
