import { GetKitchenSettingsResponse } from "types/kitchen";
import { SettingsKeys, SettingsLabels } from "./config";
import {
  SettingOptionType,
  SettingsFormState,
  SettingsTableData,
} from "./types";
import { createDefaultValues } from "helpers/dataHelpers";

const generateDataRows = (
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: Record<string, SettingOptionType<any>>,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): SettingsTableData<any>[] => {
  return Object.entries(data).map(setting => ({
    key: setting[0] as SettingsKeys,
    name: SettingsLabels[setting[0]],
    ...setting[1],
  }));
};

export const getSeparatedTableData = (
  data: SettingsFormState,
): {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  orders: SettingsTableData<any>[];
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  notifications: SettingsTableData<any>[];
} | null => {
  if (!data) {
    return { orders: [], notifications: [] };
  }
  const {
    notificationSoundDuration,
    notificationPlaySound,
    notificationSound,
    ...rest
  } = data;

  const notifications = generateDataRows({
    notificationPlaySound,
    notificationSoundDuration,
    notificationSound,
  });

  const orders = generateDataRows(rest);

  return { orders, notifications };
};

export const createStateSettings = (
  data: GetKitchenSettingsResponse,
): SettingsFormState => {
  const {
    allowCancelingOrders,
    automaticallyAcceptOrder,
    displayItemPrices,
    notificationPlaySound,
    notificationSound,
    notificationSoundDuration,
    defaultPreparationTime,
    manualHandleDelivery,
    snoozeIfOutOfStock,
    unSnoozeIfStock,
  } = data;

  return {
    allowCancelingOrders: createDefaultValues(allowCancelingOrders),
    automaticallyAcceptOrder: createDefaultValues(automaticallyAcceptOrder),
    displayItemPrices: createDefaultValues(displayItemPrices),
    manualHandleDelivery: createDefaultValues(manualHandleDelivery),
    snoozeIfOutOfStock: createDefaultValues(snoozeIfOutOfStock),
    unSnoozeIfStock: createDefaultValues(unSnoozeIfStock),
    defaultPreparationTime: createDefaultValues(defaultPreparationTime),

    notificationPlaySound: createDefaultValues(notificationPlaySound),
    notificationSound: createDefaultValues(notificationSound),
    notificationSoundDuration: createDefaultValues(notificationSoundDuration),
  };
};
